import React, { ChangeEvent, useState, useEffect } from "react";
import Search from "assets/search.svg";
import ImageHolder from "./ImagePlaceholder";
import miscsStyles from "styles/miscs/miscs.module.scss";
import { searchGames, searchUsers } from "store/matchMaking/actions";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { searchChats } from "store/messaging/action";
import { searchCommunities } from "store/community/action";
import { RootState } from "store/index";
import { searchLiveStreams } from "store/streaming/actions";

interface SearchBarProps {
  result?: string;
  placeholder?: string;
  searchIcon?: boolean;
  type?: string;
  setResults?: (results: any[]) => void;
  onClick?: (data: any) => void;
  selected?: number[];
  onPress?: (data: any) => void;
  value?: string;
}

const SearchBar: React.FC<SearchBarProps> = ({
  placeholder,
  searchIcon,
  type,
  selected,
  onClick,
  setResults,
  onPress,
  value,
}) => {
  const [input, setInput] = useState("");
  const [visible, setVisible] = useState(false);
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const dispatch = useAppDispatch();
  const userToken = useAppSelector((state: RootState) => state.auth).userToken;
  useEffect(() => {
    if (!userToken) return;
    fetchData(input);
  }, [input]);
  const user = useAppSelector((state: RootState) => state.user).user?._id;
  const matchMaking = useAppSelector((state: RootState) => state.matchMaking);

  const spectators = matchMaking.spectators;

  const fetchData = (value: string) => {
    switch (type) {
      case "games":
        dispatch(
          searchGames({
            payload: { query: value, page: "1" },
            callback: (results) => {
              setSearchResults(results.games);
              setResults && setResults(results.games);
            },
          })
        );
        break;
      case "inviteCommunity":
      case "challengePeople":
      case "invitePeople":
        dispatch(
          searchUsers({
            payload: { query: value, page: "1" },
            callback: (results) => {
              setSearchResults(results.users);
              setResults && setResults(results.users);
              console.log(results);
            },
          })
        );
        break;
      case "people":
        dispatch(
          searchUsers({
            payload: { query: value, page: "1" },
            callback: (results) => {
              setSearchResults(results.users);
              setResults && setResults(results.users);
              console.log(results);
            },
          })
        );
        break;
      case "chats":
        dispatch(
          searchChats({
            payload: { query: value, page: "1" },
            callback: (results) => {
              setSearchResults(results.chats);
              setResults && setResults(results.chats);
              console.log(results);
            },
          })
        );
        break;
      case "communities":
        dispatch(
          searchCommunities({
            payload: { query: value, page: "1" },
            callback: (results) => {
              setSearchResults(results.communities);
              setResults && setResults(results.communities);
              console.log(results);
            },
          })
        );
        break;
      case "lives":
        dispatch(
          searchLiveStreams({
            payload: { searchKey: value, page: "1" },
            callback: (results: any) => {
              setSearchResults(results.lives);
              setResults && setResults(results.lives);
            },
          })
        ); //TODO
        break;
      case "gameHub":
        onPress?.(value);
        break;
      default:
        break;
    }
  };
  const GameSearchResult = ({ result }: any) => {
    return (
      <button
        className="flex w-full flex-row gap-2 items-center my-2 rounded-xl hover:bg-primary"
        onClick={() => onClick?.(result)}
      >
        <img className="flex rounded-l w-20" src={result.image} alt="game" />
        {result.name}
      </button>
    );
  };
  const GameSearchResultsList = ({ results }: any) => {
    return (
      <div>
        {results?.map((result: any, id: number) => {
          return <GameSearchResult result={result} key={id} />;
        })}
      </div>
    );
  };

  const PeopleSearchResult = ({ result }: any, _id: string) => {
    return (
      <>
        {user !== result._id && (
          <div className="flex flex-row bg-borderColor items-center justify-between gap-5 w-full h-16 max-md:w-full rounded-lg p-2 max-md:p-1">
            <div className="flex flex-row items-center justify-between gap-5">
              <ImageHolder
                className="flex w-[50px] h-[50px] rounded-full p-1"
                uri={result.profilePicture}
              />
              <span className="span-placeholder">{result.username}</span>
            </div>
            <button
              className={`flex text-black max-w-[150px] h-[35px] rounded-full p-1 items-center justify-center w-2/5  max-md:w-1/3 ${selected?.includes(result._id) ? "bg-darkGray" : "bg-primary"
                }`}
              onClick={() => {
                onClick?.(result);
                setInput("");
              }}
              disabled={
                type === "inviteCommunity" && selected?.includes(result._id)
              }
            >
              <span>
                {type === "challengePeople"
                  ? selected?.includes(result._id)
                    ? "Cancel"
                    : "Challenge"
                  : type === "invitePeople"
                    ? selected?.includes(result._id)
                      ? "Cancel"
                      : "Invite"
                    : type === "inviteCommunity"
                      ? selected?.includes(result._id)
                        ? "Invited"
                        : "Invite"
                      : ""}
              </span>
            </button>
          </div>
        )}
      </>
    );
  };

  const PeopleSearchResultsList = ({ results }: any) => {
    return (
      <div>
        {results?.map((result: any, id: number) => {
          return (
            <>
              {!spectators.includes(result._id) && (
                <PeopleSearchResult result={result} key={id} />
              )}
            </>
          );
        })}
      </div>
    );
  };

  const AllPeopleSearchResult = ({ result }: any) => {
    return (
      <div className="flex flex-row items-center justify-between gap-5">
        <button
          className="flex w-full flex-row gap-2 items-center my-2 rounded-xl hover:bg-primary"
          onClick={() => onClick?.(result)}
        >
          <ImageHolder
            className="flex w-[50px] h-[50px] rounded-full p-1"
            uri={result.profilePicture}
          />

          <span className="span-placeholder">{result.username}</span>
        </button>
      </div>
    );
  };

  const AllPeopleSearchResultsList = ({ results }: any) => {
    return (
      <div>
        {results?.map((result: any, id: number) => {
          return <AllPeopleSearchResult result={result} key={id} />;
        })}
      </div>
    );
  };

  const ChatsSearchResult = ({ result }: any) => {
    return (
      <div className="flex flex-row items-center justify-between gap-5">
        <button
          className="flex w-full flex-row gap-2 items-center my-2 rounded-xl hover:bg-primary"
          onClick={() => { onClick?.(result); setVisible(false) }}
        >
          <ImageHolder
            className="flex w-[50px] h-[50px] rounded-full p-1"
            uri={result.user?.profilePicture}
          />

          <span className="text-black span-black">{result.user?.username}</span>
        </button>
      </div>
    );
  };

  const ChatsSearchResultsList = ({ results }: any) => {
    return (
      <div>
        {results?.map((result: any, id: number) => {
          return <ChatsSearchResult result={result} key={id} />;
        })}
      </div>
    );
  };

  const CommunitiesSearchResult = ({ result }: any) => {
    return (
      <div className="flex flex-row items-center justify-between gap-5">
        <button
          className={`flex w-full flex-row gap-2 items-center my-2 rounded-xl ${result.joined ? "hover:bg-primary" : ""
            }`}
          onClick={() => { onClick?.(result); setVisible(false) }}
          disabled={!result.joined}
        >
          <ImageHolder
            className="flex w-[70px] h-[50px] rounded-xl p-1"
            uri={result?.coverImage}
          />

          <span className="text-black span-black span-left">{result?.name}</span>
        </button>
        {!result.joined && (
          <button
            className={`flex max-w-[150px] h-[35px] rounded-full p-1 items-center justify-center w-2/5  max-md:w-1/3 bg-primary hover:bg-primaryActive
            }`}
            onClick={() => { onClick?.(result); setVisible(false) }}
          >
            <span className="text-black span-black font-bold">Join</span>
          </button>
        )}
      </div>
    );
  };

  const CommunitiesSearchResultList = ({ results }: any) => {
    return (
      <div>
        {results?.map((result: any, id: number) => {
          return <CommunitiesSearchResult result={result} key={id} />;
        })}
      </div>
    );
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    setInput(inputValue);
    setVisible(true);
    onPress?.(inputValue);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && input) {
      fetchData(input);
      setVisible(true);
    }
  };

  return (
    <div className="flex flex-col">
      <div className="w-full min-h-[64px] bg-borderColor rounded-xl flex items-center px-[40px] max-md:mt-5  ">
        <div className="flex flex-row bg-borderColor align-center gap-5 md:w-full h-16 rounded-lg p-2 ">
          {searchIcon && (
            <button>
              <ImageHolder uri={Search} width={30} height={30} />
            </button>
          )}
          <input
            className="w-full outline-none span-left span-white bg-borderColor"
            placeholder={placeholder}
            value={value ? input || value : input}
            onChange={onChange}
            onKeyDown={onKeyDown}
          />
        </div>
      </div>
      {type === "games" && input && visible && (
        <div className="w-3/4 min-h-[64px] span-white bg-borderColor rounded-xl flex items-center mb-2 ml-20 max-md:ml-4 px-[40px] max-md:mt-24 absolute top-40 z-30 md:mt-10">
          <div className="flex text-white w-full max-h-[50vh] flex-col align-center gap-5 p-2 overflow-y-auto ">
            {searchResults.length ? (
              <GameSearchResultsList results={searchResults} />
            ) : (
              <span className="span">This game was not found!</span>
            )}
          </div>
        </div>
      )}
      {(type === "challengePeople" ||
        type === "invitePeople" ||
        type === "inviteCommunity") &&
        input &&
        visible && (
          <div
            className="w-full h-full  z-10 overflow-hidden"
            onClick={() => setVisible(false)}
          >
            <div className="desktop:w-3/4 min-h-[64px] bg-borderColor rounded-xl flex gap-3 items-center mb-2 max-md:mt-2 top-[30vh]">
              <div
                className={`flex w-full max-h-[50vh] flex-col align-center gap-5 p-2 overflow-y-auto ${miscsStyles.beautifulScrollbarHidden}`}
              >
                {searchResults.length ? (
                  <PeopleSearchResultsList results={searchResults} />
                ) : (
                  <span className="text-white span">No result!</span>
                )}
              </div>
            </div>
          </div>
        )}
      {type === "people" && input && visible && (
        <div
          className="w-full h-full fixed z-10 overflow-hidden"
          onClick={() => setVisible(false)}
        >
          <div className="w-1/4 min-h-[64px] bg-backgroundGray rounded-xl flex items-center mb-2 max-md:mt-5 absolute top-[63px]">
            <div
              className={`flex w-full max-h-[50vh] flex-col align-center gap-5 p-2 overflow-y-auto ${miscsStyles.beautifulScrollbarHidden}`}
            >
              {searchResults.length ? (
                <AllPeopleSearchResultsList results={searchResults} />
              ) : (
                <span className="span text-white">This username was not found!</span>
              )}
            </div>
          </div>
        </div>
      )}
      {type === "chats" && input && visible && (
        <div className="md:w-full min-h-[64px] bg-placeholder rounded-xl flex items-center mb-2 max-md:mt-2 top-[30vh]">
          <div className="flex w-full max-h-[30vh] flex-col align-center gap-5 p-2 overflow-y-auto ">
            {searchResults.length ? (
              <ChatsSearchResultsList results={searchResults} />
            ) : (
              <span className="span text-black">This chat was not found!</span>
            )}
          </div>
        </div>
      )}
      {type === "communities" && input && visible && (
        <div className="md:w-full min-h-[64px] bg-placeholder rounded-xl flex items-center mb-2 max-md:mt-1 top-[30vh]">
          <div className="flex w-full max-h-[30vh] flex-col align-center gap-5 p-2 overflow-y-auto ">
            {searchResults.length ? (
              <CommunitiesSearchResultList results={searchResults} />
            ) : (
              <span className="span text-black">This community was not found!</span>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchBar;
