import { toast } from "react-toastify";
import {
  GetAllStakingEventsApi,
  getStakeAmountApi,
  getStakedMatchesApi,
  stakeApi,
} from "../../api/common.api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getStakeAvailableChallengesApi,
  getStakedChallengesApi,
  stakeSpecificChallengeApi,
} from "api/miniGame.api";
import {
  getStakeAvailableTournamentsApi,
  getStakedTournamentsApi,
  stakeSpecificTournamentsApi,
} from "api/tournament.api";

interface WithCallback {
  payload?: any;
  callback?: (data: any) => void;
}

export const getStakeAmount = createAsyncThunk<any, any>(
  "match/getStakeAmount",
  async (matchID, { rejectWithValue }) => {
    try {
      const data: any = await getStakeAmountApi(matchID);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

// DUELS
export const setStake = createAsyncThunk<any, WithCallback>( //Stake to duel
  "match/setStake",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await stakeApi(payload);
      callback?.(data);
      return data;
    } catch (error: any) {
      toast.error(error.message);
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);
export const getStakeAvailableDuels = createAsyncThunk<any, WithCallback>(
  "stake/getStakeAvailableDuels",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await GetAllStakingEventsApi(payload);
      callback?.(data);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);
export const getStakedMatches = createAsyncThunk<any, WithCallback>(
  "stake/getStakedMatches",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await getStakedMatchesApi(payload);
      callback?.(data);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

// CHALLENGES
export const getStakeAvailableChallenges = createAsyncThunk<any, WithCallback>(
  "staking/getStakeAvailableChallenges",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await getStakeAvailableChallengesApi(payload);
      callback?.(data);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);
export const getStakedChallenges = createAsyncThunk<any, WithCallback>(
  "staking/getStakedChallenges",
  async ({ callback }, { rejectWithValue }) => {
    try {
      const data: any = await getStakedChallengesApi();
      callback?.(data);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);
export const stakeSpecificChallenge = createAsyncThunk<any, WithCallback>(
  "staking/stakeSpecificChallenge",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await stakeSpecificChallengeApi(payload);
      callback?.(data);
      return data;
    } catch (error: any) {
      toast.error(error.message);
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

// TOURNAMENTS
export const getStakeAvailableTournaments = createAsyncThunk<any, WithCallback>(
  "staking/getStakeAvailableTournaments",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await getStakeAvailableTournamentsApi(payload);
      callback?.(data);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);
export const getStakedTournaments = createAsyncThunk<any, WithCallback>(
  "staking/getStakedTournaments",
  async ({ callback }, { rejectWithValue }) => {
    try {
      const data: any = await getStakedTournamentsApi();
      callback?.(data);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);
export const stakeSpecificTournament = createAsyncThunk<any, WithCallback>(
  "staking/stakeSpecificTournament",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await stakeSpecificTournamentsApi(payload);
      callback?.(data);
      return data;
    } catch (error: any) {
      toast.error(error.message);
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);
