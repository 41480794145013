import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AppDispatch, RootState } from "store/index";
import { getCommunityMembers, inviteCommunity } from "store/community/action";
import { getCommunityDuelists, getCommunityFriends } from "store/user/action";
import { toast } from "react-toastify";

import CloseIcon from "assets/close-button.svg";
import ProgressLines from "components/ProgressLines";
import ButtonDc from "components/Button";
import SearchBar from "components/Search";
import InvitePlayerList from "./InvitePlayerList";
import LoadingAnim from "components/Loader";

export default function InvitePeopleToCommunityScreen({ }) {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  const {
    communitiesName,
    communityId,
    friendsPage,
    duelistPage,
    friends,
    duelists,
    communityParticipants,
    loader,
  } = useAppSelector((state: RootState) => state.community);
  const [results, setResults] = useState<any[]>([]);
  const [players, setPlayers] = useState<any[]>([]);
  const [invitedPlayers, setInvitedPlayers] = useState<number[]>(
    communityParticipants.map((participant) => participant._id)
  );
  const [disable, setDisable] = useState(true);
  const [selected, setSelected] = useState("FRIENDS");
  const { from } = useParams();

  const onCancel = () => {
    if (from === "invite-duelists")
      return navigate(`/communication/main/${communityId}`);
    navigate("/communication/main");
  };

  const onPressNext = () => {
    if (from === "invite-duelists")
      return navigate(`/communication/main/${communityId}`);
    navigate("/communication/main");
  };

  const handleInviteFriend = (item: any) => {
    sendInvitation(item);
    setInvitedPlayers((prevArray) => [...prevArray, item._id]);
  };

  const handleInviteDuelist = (item: any) => {
    sendInvitation(item);
    setInvitedPlayers((prevArray) => [...prevArray, item._id]);
  };

  const sendInvitation = (item: any) => {
    setDisable(false);
    dispatch(
      inviteCommunity({
        payload: {
          userId: item._id,
          communityId: communityId,
        },
        callback: () => toast("Invitation sent!"),
      })
    );
  };
  const handleSearch = (id: any) => {
    setInvitedPlayers((prevPlayers) =>
      prevPlayers.includes(id._id)
        ? prevPlayers.filter((playerId) => playerId !== id._id)
        : [...prevPlayers, id._id]
    );
    setDisable(false);
    dispatch(
      inviteCommunity({
        payload: {
          userId: id,
          communityId: communityId,
        },
      })
    );
  };
  useEffect(() => {
    dispatch(getCommunityMembers({ query: `/${communityId}` }));
    dispatch(getCommunityFriends({ page: friendsPage }));
    dispatch(getCommunityDuelists({ page: duelistPage }));
  }, [selected, dispatch, friendsPage, duelistPage, communityId]);

  useEffect(() => {
    setInvitedPlayers(
      communityParticipants.map((participant) => participant._id)
    );
  }, [communityParticipants]);

  useEffect(() => {
    if (selected === "FRIENDS") return setPlayers(friends);
    setPlayers(duelists);
  }, [duelists, friends, selected]);

  return (
    <div className="mx-auto w-full  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex flex-col  h-screen ">
      <div className="container flex flex-col gap-y-[20px] md:my-16 max-md:p-5 items-center">
        <div
          className="  backdrop-blur-sm con rounded-[34px] p-10
        overflow-y-auto w-[calc(100%-176px)] h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)]  max-md:h-[95vh]"
        >
          <div className="flex flex-col gap-[1em]">
            <div className="w-full rounded-t-[34px] flex items-center justify-between">
              <div className="flex flex-row gap-4 items-center justify-center">
                <span className="text-3xl text-white items-center font-normal max-md:text-sm">
                  The {communitiesName} Community
                </span>
              </div>
              <button
                className=" md:w-[25px] md:h-[25px] w-5 absolute md:right-10 right-4"
                onClick={onCancel}
              >
                <img src={CloseIcon} alt="Close" />
              </button>
            </div>
          </div>

          <div className="flex flex-col w-full justify-center my-10 items-center md:gap-y-8 gap-4">
            <span className=" text-white text-center md:w-[420px] w-full text-sm md:text-lg">
              Invite People
            </span>
            <div className="flex flex-col items-center max-md:w-full">
              <div className="absolute text-white md:w-[calc(100%-176px)] w-5/6">
                <SearchBar
                  searchIcon
                  placeholder="Search people"
                  type="inviteCommunity"
                  setResults={(v) => setResults(v)}
                  onClick={handleSearch}
                  selected={invitedPlayers}
                />
              </div>
              <div className="flex flex-row justify-center mt-24 md:gap-10 gap-4 ">
                <button
                  className={`max-md:w-[30vw] h-14 w-48 bg-borderColor rounded-xl border-0  flex flex-col items-center justify-center  hover:ring hover:ring-[#FFD482] ${selected === "FRIENDS" ? "bg-primary" : ""
                    }`}
                  onClick={() => setSelected("FRIENDS")}
                >
                  <span
                    className={`text-l font-medium max-md:text-lg ${selected === "FRIENDS" ? " text-black" : " text-white"
                      }`}
                  >
                    Friends
                  </span>
                </button>
                <button
                  className={`max-md:w-[30vw] h-14 w-48 bg-borderColor rounded-xl border-0  flex flex-col items-center justify-center  hover:ring hover:ring-[#FFD482] ${selected === "DUELEST" ? "bg-primary" : ""
                    }`}
                  onClick={() => setSelected("DUELEST")}
                >
                  <span
                    className={`text-l font-medium max-md:text-lg ${selected === "DUELEST" ? " text-black" : " text-white"
                      }`}
                  >
                    Duelest
                  </span>
                </button>
              </div>
              <InvitePlayerList
                type="Invite"
                data={players}
                selected={invitedPlayers}
                onButtonPress={
                  selected === "FRIENDS"
                    ? handleInviteFriend
                    : handleInviteDuelist
                }
                onReachEnd={() => { }}
                hideButton={false}
              />
            </div>

            <div className="max-md:absolute px-10 bottom-10 w-full flex flex-col justify-center max-md:flex-col">
              <ProgressLines count={4} selected={4} />
              <div className="flex flex-row justify-center gap-10 mt-5 max-md:flex-col">
                <ButtonDc
                  text="Done"
                  short
                  type={"primary"}
                  action={() => onPressNext()}
                // disabled={disable} //TODO
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoadingAnim loading={loader} />
    </div>
  );
}
