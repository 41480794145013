import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AppDispatch, RootState } from "store/index";
import { setAmount } from "store/wallet/walletSlice";
import { WalletStatus } from "types/constants";
import { toast } from "react-toastify";
import { ReactComponent as Arrow } from "assets/arrowSend.svg";
import ImageHolder from "components/ImagePlaceholder";
import ButtonDc from "components/Button";
import LoadingAnim from "components/Loader";
import CloseIcon from "assets/close-button.svg";
import { numberWithDecimalRegex } from "constant/validations";

export default function ConfirmAmout({ }) {
  const dispatch: AppDispatch = useAppDispatch();
  const navigate = useNavigate();
  const { from } = useParams();
  const { loader, balance, receiver } = useAppSelector((state) => state.wallet);
  const user = useAppSelector((state: RootState) => state.user.user);
  const [value, setValue] = useState("");

  const onCancel = () => {
    navigate("/wallet/main");
  };

  const handleInputTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    if (!numberWithDecimalRegex.test(inputValue) && inputValue !== "") {
      toast.warning("Please enter a valid amount.");
      return;
    }
    setValue(inputValue);
  };
  const onContinue = () => {
    if (from === WalletStatus.SEND && Number(value) === Number(balance.toFixed(2))) return toast.warning("You cannot send the total amount!");
    if (from === WalletStatus.SEND && Number(value) > balance) return toast.warning("Insufficient Balance!");
    if (Number(value) < 10) return toast.warning("Minimum amount is 10$.");
    dispatch(setAmount(value));
    navigate(`/wallet/transaction-summery/${from}`);
  };

  return (
    <div className="mx-auto w-full  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex flex-col  h-screen ">
      <div className="container flex flex-col gap-y-[20px] md:my-16 max-md:p-5 items-center">
        <div
          className="  backdrop-blur-sm con rounded-[34px] p-10
        overflow-y-auto w-[calc(100%-176px)] h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)]  max-md:h-[95vh]"
        >
          <div className="flex flex-col gap-[1em]">
            <div className="w-full rounded-t-[34px] flex items-center justify-between">
              <div className="flex flex-row gap-4 items-center justify-center">
                <span className="text-3xl text-white items-center font-normal">
                  {from === WalletStatus.SEND ? "Send Money" : "Request Money"}
                </span>
              </div>
              <button
                className=" w-[25px] h-[25px] absolute right-10"
                onClick={onCancel}
              >
                <img src={CloseIcon} alt="Close" />
              </button>
            </div>
            <div className="flex flex-row justify-center items-center h-full">
              <div className="flex flex-col items-center justify-center gap-10">
                <div className="flex flex-row gap-8 items-center justify-center mt-8">
                  <div className="flex flex-col justify-center items-center">
                    <ImageHolder
                      uri={user?.profilePicture}
                      className=" w-[7em] h-[6em] rounded-2xl max-md:h-[4em]"
                    />
                    <span className="text-white text-xl">You</span>
                  </div>
                  <div
                    className={`${from === WalletStatus.REQUEST
                      ? "transform rotate-180"
                      : "flex"
                      }`}
                  >
                    <Arrow />
                  </div>
                  <div className="flex flex-col justify-center items-center">
                    <ImageHolder
                      uri={receiver?.profilePicture}
                      className=" w-[7em] h-[6em] rounded-2xl max-md:h-[4em]"
                    />
                    <span className="text-white text-xl">
                      {receiver?.username}
                    </span>
                  </div>
                </div>
                <span className="text-white text-xl max-md:text-lg">
                  Enter Amount to{" "}
                  {from === WalletStatus.SEND ? "send to" : "Request from"}{" "}
                  <span className="text-primary text-xl max-md:text-lg">
                    {receiver?.username}
                  </span>
                </span>
                <div className="flex flex-col items-center justify-center">
                  <input
                    className="bg-inputGray rounded-lg h-16 text-center border-inputGray text-[18px] text-white"
                    placeholder="$ 00.00"
                    value={value}
                    onChange={handleInputTitle}
                  />
                  <span className="text-placeholder">
                    Minimum amount is $10
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-center gap-10 mt-16 max-md:flex-col">
            <div>
              <ButtonDc
                text="Back"
                short
                action={() => navigate(`/wallet/send-money/${from}`)}
              />
            </div>
            <div>
              <ButtonDc
                text="Continue"
                short
                type={"primary"}
                action={() => onContinue()}
              />
            </div>
          </div>
        </div>
      </div>
      <LoadingAnim loading={loader} />
    </div>
  );
}
