import { toast } from "react-toastify";
import Api from "../services/api";
import {
  allEventsUrl,
  getAllTournamentsUrl,
  livesUrl,
  getGamesUrl,
  getStakesAvailableEventsUrl,
  getPrizePoolValueUrl,
  getWinnersUrl,
  createMatchUrl,
  getCommunityFriendsUrl,
  getCommunityDuelistsUrl,
  inviteToMatchUrl,
  getPresetsUrl,
  sendOpponentInvitationUrl,
  joinLobbyUrl,
  getNotificationsUrl,
  handleNotificationUrl,
  getOtherUserUrl,
  userUrl,
  getMatchmakingDuelsUrl,
  sendPayPalAuthCodeUrl,
  linkedAccountsUrl,
  checkBalanceUrl,
  capturePaymentUrl,
  digitalPaymentUrl,
  subscribeToTopicUrl,
  getDisputesUrl,
  closeMatchUrl,
  uploadImageUrl,
  searchGamesUrl,
  usersSearchUrl,
  getStakeAmountUrl,
  stakeUrl,
  getStakedMatchesUrl,
  followUserUrl,
  unFollowUserUrl,
  getFollowersUrl,
  getFollowingUrl,
  leaveLobbyUrl,
  getCurrentMatchUrl,
  getPrivateChatListUrl,
  getCommunityListUrl,
  getPersonalChatMessagesUrl,
  createCommunityUrl,
  sendPersonalMessageUrl,
  deletePrivateChatUrl,
  searchChatUrl,
  searchCommunityUrl,
  getCommunityChatUrl,
  sendCommunityMessageUrl,
  joinCommunityUrl,
  leaveCommunityUrl,
  deleteCommunityUrl,
  editCommunityUrl,
  getCommunityParticipantsUrl,
  removeFollowerUrl,
  inviteCommunityUrl,
  leaderBoardUrl,
  checkPasswordUrl,
  techSupportUrl,
  deleteAccountUrl,
  createSteamUrl,
  streamForExternalUrl,
  getLiveStreamsUrl,
  endStreamUrl,
  startRecordingUrl,
  stopRecordingUrl,
  referralUrl,
  deleteFacebookAccountUrl,
  deleteAppleAccountUrl,
  deleteGoogleAccountUrl,
  enableStakingUrl,
  getMatchTimerUrl,
  stakeAvailableEventsUrl,
} from "./urls";

export const GetAllEventsApi = () => {
  return Api.request({
    method: "get",
    url: allEventsUrl,
    publicApi: true,
  });
};

export const GetAllLivesApi = (query?: any) => {
  return Api.request({
    method: "get",
    url: livesUrl + `?streamMode=${query}`,
    publicApi: true,
  });
};

export const GetAllStakingEventsApi = (data: any) => {
  return Api.request({
    method: "put",
    url: getStakesAvailableEventsUrl,
    publicApi: false,
    data,
  });
};

export const GetAllTournamentsApi = () => {
  return Api.request({
    method: "put",
    url: getAllTournamentsUrl,
    publicApi: true,
  });
};

export const GetGamesApi = (page: number) => {
  return Api.request({
    method: "get",
    url: getGamesUrl,
    publicApi: false,
    data: {
      page,
    },
  });
};

export const getPrizePoolValueApi = () => {
  return Api.request({
    method: "get",
    url: getPrizePoolValueUrl,
    publicApi: true,
  });
};

export const getWinnersApi = () => {
  return Api.request({
    method: "get",
    url: getWinnersUrl,
    publicApi: true,
  });
};

export const createMatchApi = (body: object) => {
  return Api.request({
    publicApi: false,
    method: "post",
    url: createMatchUrl,
    body,
  });
};

export const getCommunityFriendsApi = (page: number) => {
  return Api.request({
    method: "get",
    url: getCommunityFriendsUrl,
    publicApi: false,
    data: {
      page,
    },
  });
};

export const getCommunityDuelistsApi = (page: number) => {
  return Api.request({
    method: "get",
    url: getCommunityDuelistsUrl,
    publicApi: false,
    data: {
      page,
    },
  });
};

export const inviteToMatchApi = (body: object) => {
  return Api.request({
    publicApi: false,
    method: "post",
    url: inviteToMatchUrl,
    body,
  });
};

export const challengeToMatchApi = (body: object) => {
  return Api.request({
    publicApi: false,
    method: "post",
    url: sendOpponentInvitationUrl,
    body,
  });
};

export const getPresetsApi = () => {
  return Api.request({
    method: "get",
    url: getPresetsUrl,
    publicApi: false,
  });
};

export const joinLobbyApi = (body: any) => {
  return Api.request({
    method: "put",
    url: joinLobbyUrl,
    body,
  });
};

export const getNotificationssApi = (page: number) => {
  return Api.request({
    method: "get",
    url: getNotificationsUrl,
    publicApi: false,
    data: {
      page,
    },
  });
};

export const handleNotificationApi = (body: any) => {
  return Api.request({
    method: "put",
    url: handleNotificationUrl,
    body,
  });
};

export const getOtherUserApi = (query: any) => {
  return Api.request({
    method: "get",
    url: getOtherUserUrl,
    data: {
      userId: query,
    },
  });
};

export const getUserApi = () => {
  return Api.request({
    method: "get",
    url: userUrl,
  });
};

export const getMatchmakingDuelsApi = (body: any) => {
  return Api.request({
    method: "put",
    url: getMatchmakingDuelsUrl,
    body,
  });
};

export const getLinkedAccountsApi = () =>
  Api.request({
    method: "get",
    url: linkedAccountsUrl,
  });

export const sendPayPalAuthCodeApi = (body: object) => {
  return Api.request({
    method: "put",
    url: sendPayPalAuthCodeUrl,
    body,
  });
};

export const checkIfBalanceIsEnoughApi = (body: object) => {
  return Api.request({
    method: "put",
    url: checkBalanceUrl,
    body,
  });
};

export const capturePaymentApi = (body: any) =>
  Api.request({
    method: "post",
    url: capturePaymentUrl,
    body,
  });

export const digitalPaymentApi = (body: any) =>
  Api.request({
    method: "post",
    url: digitalPaymentUrl,
    body,
  });

export const editUserApi = (body: any) => {
  return Api.request({
    method: "put",
    url: userUrl,
    body,
  });
};

export const subscribeToTopicApi = (body: any) => {
  return Api.request({
    method: "post",
    url: subscribeToTopicUrl,
    body,
  });
};

export const getDisputesApi = () => {
  return Api.request({
    method: "get",
    url: getDisputesUrl,
  });
};

export const closeMatchApi = (body: object) => {
  return Api.request({
    method: "post",
    url: closeMatchUrl,
    body,
  });
};

export const uploadImageApi = (base64Image: string | URL | Request) => {
  const formData = new FormData();
  const blob = fetch(base64Image)
    .then((res) => res.blob())
    .then((blob) => {
      formData.append("user-image", blob, "image.png");
      return Api.request({
        method: "put",
        url: uploadImageUrl,
        body: formData,
        file: true,
      });
    })
    .catch((error) =>
      console.error("Error in converting base64 to blob:", error)
    );

  return blob;
};

export const searchGamesApi = (query: any, page: number) =>
  Api.request({
    method: "get",
    url: searchGamesUrl,
    data: {
      query,
      page,
    },
  });

export const searchUsersApi = (query: any, page: number) =>
  Api.request({
    method: "get",
    url: usersSearchUrl,
    data: {
      query,
      page,
    },
  });

export const getStakeAmountApi = (matchId: string) =>
  Api.request({
    method: "get",
    url: getStakeAmountUrl,
    data: {
      matchId,
    },
  });

export const stakeApi = (body: object) =>
  Api.request({
    method: "post",
    url: stakeUrl,
    body,
  });

export const getStakedMatchesApi = (data: object) =>
  Api.request({
    method: "get",
    url: getStakedMatchesUrl,
    data,
  });

export const followUserApi = (body: object) => {
  return Api.request({
    method: "put",
    url: followUserUrl,
    body,
  });
};

export const unfollowUserApi = (body: object) => {
  return Api.request({
    method: "put",
    url: unFollowUserUrl,
    body,
  });
};

export const getFollowersApi = () => {
  return Api.request({
    method: "get",
    url: getFollowersUrl,
  });
};

export const getFollowingApi = () => {
  return Api.request({
    method: "get",
    url: getFollowingUrl,
  });
};

export const leaveLobbyApi = (body: any) =>
  Api.request({
    method: "post",
    url: leaveLobbyUrl,
    body,
  });

export const getCurrentMatchApi = () => {
  return Api.request({
    method: "get",
    url: getCurrentMatchUrl,
  });
};

export const getPrivateChatListApi = (page: number) =>
  Api.request({
    method: "get",
    url: getPrivateChatListUrl,
    data: {
      page,
    },
  });

export const getCommunityListApi = (page: number) =>
  Api.request({
    method: "get",
    url: getCommunityListUrl,
    data: {
      page,
    },
  });

export const getPersonalChatMessagesApi = (query: any) => {
  return Api.request({
    method: "get",
    url: getPersonalChatMessagesUrl + query,
  });
};
export const createCommunityApi = async (data: any) => {
  const formData = new FormData();
  formData.append("name", data.name);
  formData.append("type", data.type);
  formData.append("adult", data.adult);
  formData.append("title", data.title);
  formData.append("description", data.description);

  const blob = await fetch(data.image)
    .then((res) => res.blob())
    .then((blob) => {
      formData.append("community-image", blob, "image.png");

      return Api.request({
        method: "post",
        url: createCommunityUrl,
        file: true,
        body: formData,
      });
    })
    .catch((error) =>
      console.error("Error in converting image to blob:", error)
    );
  return blob;
};

export const sendPersonalMessageApi = (body: object) => {
  return Api.request({
    method: "post",
    url: sendPersonalMessageUrl,
    body,
  });
};

export const deletePrivateChatApi = (chatId: string) => {
  return Api.request({
    method: "delete",
    url: deletePrivateChatUrl + `/${chatId}`,
  });
};

export const searchChatsApi = (query: any, page: number) =>
  Api.request({
    method: "get",
    url: searchChatUrl,
    data: {
      query,
      page,
    },
  });

export const searchCommunitiesApi = (query: any, page: number) =>
  Api.request({
    method: "get",
    url: searchCommunityUrl,
    data: {
      query,
      page,
    },
  });

export const getCommunityChatApi = (query: any) => {
  return Api.request({
    method: "get",
    url: getCommunityChatUrl + query,
  });
};

export const sendCommunityMessageApi = (body: object) => {
  return Api.request({
    method: "post",
    url: sendCommunityMessageUrl,
    body,
  });
};

export const joinCommunityApi = (query: any) => {
  return Api.request({
    method: "put",
    url: joinCommunityUrl + `/${query}`,
  });
};

export const leaveCommunityApi = (query: any) => {
  return Api.request({
    method: "put",
    url: leaveCommunityUrl + query,
  });
};

export const deleteCommunityApi = (query: any) => {
  return Api.request({
    method: "delete",
    url: deleteCommunityUrl + query,
  });
};

export const editCommunityApi = async (query: any, data: any) => {
  console.log(query);
  const formData = new FormData();
  formData.append("name", data.name);
  formData.append("type", data.type);
  formData.append("adult", data.adult);
  formData.append("title", data.title);
  formData.append("description", data.description);

  const blob = await fetch(data.image)
    .then((res) => res.blob())
    .then((blob) => {
      formData.append("community-image", blob, "image.png");

      return Api.request({
        method: "put",
        url: editCommunityUrl + query,
        file: true,
        body: formData,
      });
    })
    .catch((error) =>
      console.error("Error in converting image to blob:", error)
    );
  return blob;
};

export const getCommunityMembersApi = (query: any) => {
  return Api.request({
    method: "get",
    url: getCommunityParticipantsUrl + query,
  });
};

export const removeFollowerApi = (body: any) => {
  return Api.request({
    method: "put",
    url: removeFollowerUrl,
    body,
  });
};

export const inviteCommunityApi = (body: object) =>
  Api.request({
    method: "put",
    url: inviteCommunityUrl,
    body,
  });

export const leaderBoardApi = () =>
  Api.request({
    method: "get",
    url: leaderBoardUrl,
  });

export const checkPasswordApi = (password: string) => {
  return Api.request({
    method: "post",
    url: checkPasswordUrl,
    body: {
      password,
    },
  });
};

export const techSupportApi = (body: object) =>
  Api.request({
    method: "put",
    url: techSupportUrl,
    body,
  });

export const deleteAccountApi = () => {
  return Api.request({
    method: "delete",
    url: deleteAccountUrl,
  });
};

export const createSteamApi = async (data: any) => {
  const formData = new FormData();
  formData.append("streamType", data?.streamType);
  formData.append("streamMode", data?.streamMode);
  // formData.append("matchId", data?.matchId); //TODO
  formData.append("metadata[tags]", JSON.stringify(data?.metadata.tags));
  formData.append("metadata[title]", data?.metadata.title);
  formData.append("metadata[category]", data?.metadata.category);
  formData.append("metadata[description]", data?.metadata.description);

  const blob = await fetch(data.image)
    .then((res) => res.blob())
    .then((blob) => {
      formData.append("stream-image", blob, "image.png");

      return Api.request({
        method: "put",
        url: createSteamUrl,
        file: true,
        body: formData,
      });
    })
    .catch((error) => {
      toast.error(error.data.message);
    });
  return blob;
};
export const streamForExternalApi = (body: any) => {
  return Api.request({
    method: "put",
    url: streamForExternalUrl,
    body,
  });
};

export const getLiveStreamsApi = (query?: any) => {
  return Api.request({
    method: "get",
    url: getLiveStreamsUrl + `?streamMode=${query}`,
    publicApi: true,
  });
};

export const searchLiveStreamsApi = (searchKey?: string) => {
  return Api.request({
    method: "get",
    url: getLiveStreamsUrl + `?streamMode=HUB&searchKey=${searchKey}`,
  });
};

export const endStreamApi = () => {
  return Api.request({
    method: "put",
    url: endStreamUrl,
  });
};

export const startRecordingApi = () => {
  return Api.request({
    method: "post",
    url: startRecordingUrl,
  });
};
export const stopRecordingApi = () => {
  return Api.request({
    method: "post",
    url: stopRecordingUrl,
  });
};

export const createDcSteamApi = (body: any) => {
  return Api.request({
    method: "put",
    url: createSteamUrl,
    body,
  });
};

export const getReferralApi = () => {
  return Api.request({
    method: "get",
    url: referralUrl,
  });
};

export const deleteLinkedFacebookAccountApi = () =>
  Api.request({
    method: "delete",
    url: deleteFacebookAccountUrl,
  });

export const deleteLinkedAppleAccountApi = () =>
  Api.request({
    method: "delete",
    url: deleteAppleAccountUrl,
  });

export const deleteLinkedGoogleAccountApi = () =>
  Api.request({
    method: "delete",
    url: deleteGoogleAccountUrl,
  });

export const enableStakingApi = (body: object) => {
  return Api.request({
    method: "put",
    url: enableStakingUrl,
    body,
  });
};

export const getMatchTimerApi = (id: string) => {
  return Api.request({
    method: "put",
    url: getMatchTimerUrl + `/${id}`,
  });
};
export const stakeAvailableEventsApi = (data: object) =>
  Api.request({
    method: "get",
    url: stakeAvailableEventsUrl,
    data,
  });
