import React, { useCallback, useEffect, useState } from "react";
import CloseIcon from "assets/close-button.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AppDispatch, RootState } from "store/index";
import {
  deleteTournamentMatch,
  getStagedPlayers,
  getTournamentById,
} from "store/liveBrackets/actions";
import ImageHolder from "components/ImagePlaceholder";
import ButtonDc from "components/Button";
import BracketItem from "components/BracketItem";
import {
  deleteTournament,
  endTournament,
  getTournamentMatches,
  goToNextStage,
  goToTournamentMatch,
  leaveTournament,
} from "store/tournament/action";
import { toast } from "react-toastify";
import { subscribeToTopic } from "store/fcm/actions";
import {
  resetTournamentId,
  resetTournamentMaking,
  setCurrentTournament,
  setCurrentTournamentState,
  setTournamentStarting,
} from "store/tournament/tournamentSlice";
import { ReactComponent as Warning } from "assets/warning.svg";
import { ReactComponent as Info } from "assets/info.svg";
import LoadingAnim from "components/Loader";
import TournamentInfoModal from "components/TournamentInfoModal";
import {
  resetLiveBrackets,
  setCurrentTournamentStatus,
} from "store/liveBrackets/liveBracketSlice";
import {
  resetCurrentMatch,
  setScreenStatus,
} from "store/currentMatch/currentMatchSlice";
import CountdownTimer from "components/CountdownTimer";
import Refresh from "assets/refresh_icon.svg";
import { resetBackgroundMatch } from "store/matchMaking/matchMakingSlice";

export default function LiveBracketsScreen({ }) {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  const [host, setHost] = useState();

  const { nextRound } = useParams();
  const fcmData = useAppSelector((state) => state.fcm);
  const tournament = useAppSelector((state: RootState) => state.tournament);
  const liveBracketTournament = useAppSelector(
    (state: RootState) => state.liveBrackets.tournamentInfo
  );
  const liveBracket = useAppSelector((state: RootState) => state.liveBrackets);
  const currentTournament = tournament.currentTournament;
  const tournamentId: any = tournament.tournamentId
    ? tournament.tournamentId
    : nextRound;
  const liveBracketTournamentStage = liveBracketTournament?.currentStage;
  const liveBracketMatches = liveBracketTournament?.matchList?.filter(
    (match) => match.stage === liveBracketTournamentStage
  );
  const [showMatchInfo, setShowMatchInfo] = useState(false);
  const [isStartButtonClicked, setIsStartButtonClicked] = useState(false);
  const stagedPlayers = liveBracketTournament.players;
  const tournamentDate = liveBracketTournament.tournamentDate;
  const isTimerEnd = new Date(tournamentDate) <= new Date();
  const matches = liveBracketTournament.matchList;
  const liveBracketTournamentStatus = liveBracketTournament?.tournamentStatus;
  const liveBracketTournamentDate = liveBracketTournament?.tournamentDate;

  const liveBracketTournamentState = liveBracketTournament?.tournamentStatus;
  const matchWithoutWinner = liveBracketMatches.filter(
    (match) => !match.winner
  );
  const isTournamentComplete =
    liveBracketMatches.length === 1 && liveBracketMatches[0]?.winner;
  const userMatch = liveBracketMatches.find((item) => item.isUserIn);
  const isPaused = liveBracketTournament?.isPaused;
  const user = useAppSelector((state: RootState) => state.user).user;
  const tournamentHost = liveBracketTournament?.host === user?._id;
  const isTournamentStarting =
    liveBracketTournamentStatus === "STARTED" &&
    liveBracketTournamentDate &&
    new Date(liveBracketTournamentDate) > new Date();

  const isTournamentScheduled = liveBracketTournamentStatus === "SCHEDULED";
  const isMatchNotStarted = liveBracketTournamentStatus === "NOT_STARTED";

  const goToMatchButtonDisabled =
    liveBracketTournamentStatus !== "STARTED" ||
    !userMatch ||
    !isTimerEnd ||
    isPaused;

  const startButtonDisabled =
    liveBracketMatches.length === 0 ||
    (liveBracketTournamentState === "STARTED" &&
      liveBracketMatches.find((m) => !m.winner)) ||
    isTournamentStarting ||
    isStartButtonClicked; //TODO

  const isMatchButtonVisible =
    ((tournamentHost && isTournamentScheduled) ||
      (tournamentHost && isMatchNotStarted) ||
      (tournamentHost && isPaused)) &&
    !isTournamentStarting;
  const { selectedDuelPath } = useAppSelector((state) => state.matchMaking);
  const isOpponent =
    selectedDuelPath === "OPPONENT_JOIN_MATCH" ||
    selectedDuelPath === "OPPONENT_JOIN";

  const players = liveBracket.tournamentInfo.players;
  const userId = useAppSelector((state: RootState) => state.user).user?._id;
  const isUserInTheMatch = players.find((item: any) => item.user === userId) ? true : false



  useEffect(() => {
    dispatch(getTournamentById({ payload: tournamentId }));
  }, [tournamentId]);

  const onCancel = () => {
    dispatch(resetBackgroundMatch());
    if (liveBracketTournamentState === "ENDED") return navigate("/wagering");
    dispatch(
      getTournamentById({ payload: tournamentId, errLog: () => navigate("/") })
    );
  };

  const renderStagedPlayers = (player: any, index: number) => {
    const isLost = player.currentStage < liveBracketTournamentStage;
    const truncatedUsername =
      player.username.length > 8 ? `${player.username.slice(0, 8)}...` : player.username;

    return (
      <div
        key={index}
        className="flex flex-col items-center justify-center gap-1"
      >
        <div
          className={`rounded-full border-4 p-1 ${isLost ? " border-disabled" : "border-primary"
            }`}
        >
          <ImageHolder
            uri={player.profilePicture}
            className={`flex w-[50px] h-[50px] rounded-full max-md:h-[30px] max-md:w-[30px] ${isLost ? "filter grayscale" : ""
              }`}
          />
        </div>
        <span className="text-white max-md:text-[12px]">{truncatedUsername}</span>
      </div>
    );
  };

  const createTournamentMatch = () => {
    navigate("/tournament/create-match");
  };

  const invitePlayers = () => {
    navigate(`/duels/invite-others/${tournamentId}`);
  };

  const handleMatchNavigation = useCallback(() => {
    dispatch(
      goToTournamentMatch({
        payload: userMatch?.match,
        callback: () => {
          navigate(`/duels/lobby/${userMatch?.match}`);
        },
      })
    );
  }, [userMatch?._id]);

  const renderMatches = (match: any, index: number) => {
    const deleteTournamentMatches = () => {
      if (!tournamentHost || !isUserInTheMatch) return;
      if (
        window.confirm("Are you sure you want to delete this match?") === true
      ) {
        dispatch(
          deleteTournamentMatch({
            payload: { tournamentId, matchId: match.match },
          })
        );
      }
    };

    return (
      <div key={index}>
        <BracketItem
          team1JSON={match?.team1}
          team2JSON={match?.team2}
          winner={match?.winner}
          onPress={
            liveBracketTournamentState === "ENDED"
              ? showResult
              : deleteTournamentMatches
          }
        />
      </div>
    );
  };

  const handleScheduleNextMatch = () => {
    //TODO
  };

  const endTournamentMatch = () => {
    dispatch(setScreenStatus(""));
    dispatch(
      endTournament({
        payload: tournamentId,
        callback: () => dispatch(getTournamentById({ payload: tournamentId })),
      })
    );
  };

  const handleNextRound = () => {
    if (isTournamentComplete) {
      return dispatch(
        goToNextStage({ payload: tournamentId, callback: endTournamentMatch })
      );
    }
    if (matches.length > 1 && matches.length % 2 === 1) {
      return toast.error("Please create an even number of matches!");
    }
    dispatch(
      goToNextStage({
        payload: tournamentId,
        callback: () => {
          getTournamentUsingId(tournamentId);
          dispatch(
            getTournamentMatches({
              payload: tournamentId,
              callback: () => {
                dispatch(
                  setCurrentTournament({ ...currentTournament, isPaused: true })
                );
              },
            })
          );
        },
      })
    );
  };

  const handleStartTournament = () => {
    if (liveBracketMatches.length > 1 && matches.length % 2 === 1) {
      return toast.warning("Please create an even number of matches!");
    }
    setIsStartButtonClicked(true);
    dispatch(setTournamentStarting(true));
    dispatch(setCurrentTournamentState("STARTED"));
    dispatch(setCurrentTournamentStatus("STARTED"));
    navigate(`/tournament/time-select/${tournamentId}`);
  };

  const handleDeleteTournament = () => {
    dispatch(
      deleteTournament({
        payload: tournamentId,
        callback: () => getTournamentUsingId(tournamentId),
        err: () => navigate("/"),
      })
    );
  };

  const getTournamentUsingId = (tournamentId: string) => {
    dispatch(
      getTournamentById({
        payload: tournamentId,
        errLog: () => {
          toast.error(
            "The tournament you are looking for does not exist or has been deleted. Sending you back to the menu..."
          );
          setTimeout(() => {
            navigate("/");
          }, 5000);
        },
      })
    );
  };

  const showResult = () => {
    navigate(`/tournament/summary/${tournamentId}`);
  };

  useEffect(() => {
    dispatch(subscribeToTopic({ topic: tournamentId }));
    console.log("Subscribed to topic:", tournamentId);
  }, [dispatch, tournamentId]);

  useEffect(() => {
    if (fcmData?.data) {
      switch (fcmData?.data?.type) {
        case "PLAYER_JOINED_TOURNAMENT":
          dispatch(getStagedPlayers({ payload: tournamentId }));
          break;
        case "TOURNAMENT_MATCH_CREATED":
          dispatch(getTournamentById({ payload: tournamentId }));
          break;
        case "MATCH_UPDATED":
          break;
        case "TOURNAMENT_NEXT_STAGE":
          dispatch(resetBackgroundMatch());
          dispatch(getTournamentById({ payload: tournamentId }));
          break;
        case "TOURNAMENT_STARTED":
          dispatch(setCurrentTournamentState("STARTED"));
          dispatch(setCurrentTournamentStatus("STARTED"));
          dispatch(getTournamentById({ payload: tournamentId }));
          break;
        case "TOURNAMENT_DELETED":
          if (!isOpponent) return;
          toast.error(
            "Host deleted the tournament.Sending you back to the menu..."
          );
          dispatch(resetTournamentMaking());
          dispatch(resetCurrentMatch());
          dispatch(resetBackgroundMatch());
          dispatch(resetLiveBrackets());
          dispatch(resetTournamentId());
          setTimeout(() => {
            navigate("/");
          }, 5000);
          break;
        case "TOURNAMENT_MATCH_DELETED":
          dispatch(getTournamentById({ payload: tournamentId }));
          break;
      }
    }
  }, [fcmData]);

  const onEndTimer = () => {
    if (!tournamentId) return;

    dispatch(getTournamentById({ payload: tournamentId }));
  };

  useEffect(() => {
    window.onbeforeunload = () => {
      return tournamentId === nextRound;
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  const handleLeaveTournament = () => {
    dispatch(leaveTournament({ payload: tournamentId, callback: () => { dispatch(resetBackgroundMatch()); navigate('/') } }))
  }

  return (
    <div className="mx-auto w-full  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex flex-col  h-screen ">
      <div className="container flex flex-col gap-y-[20px] md:my-16 max-md:p-5 items-center">
        <div
          className="  backdrop-blur-sm con rounded-[34px] p-10
        overflow-y-auto w-[calc(100%-176px)] h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)]  max-md:h-[95vh]"
        >
          <div className="flex flex-col gap-[1em]">
            <div className="w-full rounded-t-[34px] flex items-center justify-between">
              <span className="text-3xl text-white items-center font-normal max-md:text-[18px]">
                Tournament
              </span>
              <button
                className=" w-[25px] h-[25px] absolute right-10 "
                onClick={onCancel}
              >
                <img
                  src={
                    liveBracketTournamentState === "ENDED" ? CloseIcon : Refresh
                  }
                  alt="Close"
                />
              </button>
            </div>
            {liveBracketTournament.cubeWager === 0 && <span className="text-xl max-md:text-lg bg-2048-256 text-black text-center rounded-2xl items-center font-normal">
              Free Play Mode
            </span>}
            <span className="text-subText text-xl font-normal text-center">
              Live Brackets
            </span>
          </div>
          <div className="flex flex-col items-center justify-center gap-[2em] my-8 max-md:flex-col ">
            <div className="flex flex-row gap-5 max-md:gap-2">
              <span className="text-white text-2xl max-md:text-[12px]">
                Tournament ID: {liveBracketTournament?.tournamentId}
              </span>
              <button onClick={() => setShowMatchInfo(true)}>
                <Info />
              </button>
            </div>
            <div className="flex flex-row items-center justify-center gap-2">
              {stagedPlayers.map(renderStagedPlayers)}
            </div>
            {tournamentDate && new Date(tournamentDate) > new Date() && (
              <CountdownTimer
                until={
                  tournamentDate ? tournamentDate : new Date().toDateString()
                }
                onEnd={onEndTimer}
              />
            )}
            {isUserInTheMatch && <div className="flex flex-row max-md:flex-col items-center justify-center gap-4 max-md:gap-8 max-md:w-full">
              {isMatchButtonVisible && (
                <ButtonDc
                  text="Create a Match"
                  action={createTournamentMatch}
                  type={"primary"}
                />
              )}
              {isMatchButtonVisible && (
                <ButtonDc
                  text="Invite Players"
                  action={invitePlayers}
                  type={"primary"}
                />
              )}
            </div>}
            {userMatch && !userMatch?.winner && !isPaused && isUserInTheMatch && (
              <ButtonDc
                text="Go to Your Match"
                action={handleMatchNavigation}
                type={"primary"}
                disabled={goToMatchButtonDisabled}
              />
            )}
            {liveBracketMatches.length > 0 ? (
              <div className="flex flex-wrap justify-center">
                {liveBracketMatches?.map((match, index) => (
                  <div key={index} className="p-2">
                    {renderMatches(match, index)}
                  </div>
                ))}
              </div>
            ) : (
              <>
                <Warning />
                <span className="text-white text-4xl max-md:text-xl text-center">
                  No matches have been created yet.
                </span>
              </>
            )}
            <div>
              {!matchWithoutWinner &&
                host === user?._id &&
                !nextRound &&
                isUserInTheMatch &&
                !(matches.length === 0 && matches?.[0]?.winner) && (
                  <ButtonDc
                    text="Schedule Next Round"
                    action={handleScheduleNextMatch}
                    type={"primary"}
                  />
                )}
            </div>
            {!tournamentHost && liveBracketTournamentState === "NOT_STARTED" && isUserInTheMatch &&
              <ButtonDc
                text="Leave tournament"
                action={handleLeaveTournament}
                type={"error"}
              />}
            {!isUserInTheMatch && <div className="flex justify-center">
              <div className="flex bg-borderColor h-12 rounded-xl items-center px-7 justify-center w-full max-w-[350px]">
                <span className=" text-primary text-sm md:text-lg">Joined as a spectator</span>

              </div>
            </div>}
            {(matchWithoutWinner || nextRound) &&
              tournamentHost &&
              liveBracketTournamentState !== "ENDED" && isUserInTheMatch &&
              !(matches.length === 0 && matches?.[0]?.winner) && (
                <>
                  <ButtonDc
                    text={
                      isTournamentComplete
                        ? "End Tournament"
                        : liveBracketTournamentState === "STARTED" &&
                          !isPaused &&
                          !matches.find((m) => !m.winner)
                          ? "Go to Next Round"
                          : "Start Tournament"
                    }
                    action={
                      (liveBracketTournamentState === "STARTED" && !isPaused) ||
                        isTournamentComplete
                        ? handleNextRound
                        : handleStartTournament
                    }
                    type={"primary"}
                    disabled={
                      (startButtonDisabled && !isPaused) ||
                      liveBracketMatches.length < 0
                    }
                  />
                  {liveBracketTournamentState === "NOT_STARTED" && isUserInTheMatch && (
                    <ButtonDc
                      text="Cancel and Delete"
                      action={handleDeleteTournament}
                      type={"error"}
                    />
                  )}
                </>
              )}
            {liveBracketTournamentState === "ENDED" && (
              <ButtonDc text="Summary" action={showResult} type={"primary"} />
            )}
            {liveBracketTournamentState === "SUSPEND" && (
              <span className="text-white text-2xl">
                Tournament is suspended
              </span>
            )}
            {showMatchInfo && (
              <TournamentInfoModal
                closeModal={() => {
                  setShowMatchInfo(false);
                }}
                data={liveBracketTournament}
              />
            )}
          </div>
        </div>
      </div>
      <LoadingAnim loading={tournament.loading || liveBracket.loading} />
    </div>
  );
}
