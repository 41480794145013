import React, { useEffect, useState } from "react";
import CloseIcon from "assets/close-button.svg";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AppDispatch } from "store/index";

import ButtonDc from "components/Button";
import ProfilePicModal from "components/ProfilePicModal";
import { setCoverImage } from "store/community/communitySlice";

export default function SelectCommunityImageScreen({ }) {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  const { coverImage } = useAppSelector((state) => state.community);
  const [imageUrl, setImageUrl] = useState(coverImage ? coverImage : "");
  const [modalOpen, setModalOpen] = useState(false);

  const onCancel = () => {
    navigate("/communication/main");
  };

  const onContinue = () => {
    if (coverImage === imageUrl) {
      dispatch(setCoverImage(''));
    }
    navigate("/communication/create-community");
  };

  const updateAvatar = (imgSrc: any) => {
    setImageUrl(imgSrc);
    if (coverImage === imgSrc) return;
    dispatch(setCoverImage(imgSrc));

    setModalOpen(false);
  };

  return (
    <div className="mx-auto w-full  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex flex-col  h-screen ">
      <div className="container flex flex-col gap-y-[20px] md:my-16 max-md:p-5 items-center">
        <div
          className="  backdrop-blur-sm con rounded-[34px] p-10
        overflow-y-auto w-[calc(100%-176px)] h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)]  max-md:h-[95vh]"
        >
          <div className="flex flex-col gap-[1em]">
            <div className="w-full rounded-t-[34px] flex items-center justify-between">
              <div className="flex flex-row gap-4 items-center justify-center">
                <span className="text-3xl text-white items-center font-normal max-md:text-lg">
                  Select Community Image
                </span>
              </div>
              <button
                className=" w-[25px] h-[25px] absolute right-10"
                onClick={onCancel}
              >
                <img src={CloseIcon} alt="Close" />
              </button>
            </div>
          </div>
          <div className="flex flex-col justify-center items-center gap-y-8">
            {!imageUrl ? (
              <div className="border-2 rounded-xl border-dashed border-placeholder h-[200px] w-[300px] max-md:h-[30vh] max-md:w-full mt-8 flex justify-center items-center">
                <span className=" text-darkGray">No Image Selected</span>
              </div>
            ) : (
              <img
                src={imageUrl}
                alt="imageUpload"
                className=" h-[200px] w-[300px] max-md:h-[30vh] max-md:w-full mt-8 flex justify-center items-center"
              />
            )}

            <span className="text-center text-white text-2xl">
              Select Image
            </span>

            <div className="px-10 w-full flex flex-col justify-center gap-10 mt-16 max-md:flex-col">
              <div>
                <ButtonDc
                  text="Browse Photos"
                  short
                  action={() => setModalOpen(true)}
                />
              </div>
              <div>
                <ButtonDc
                  text="Next"
                  short
                  type={"primary"}
                  action={() => onContinue()}
                  disabled={!imageUrl}
                />
              </div>
            </div>
          </div>
        </div>
        {modalOpen && (
          <ProfilePicModal
            updateAvatar={updateAvatar}
            closeModal={() => setModalOpen(false)}
            title="Upload your Photo"
            type="COMMUNITY"
          />
        )}
      </div>
    </div>
  );
}
