import { toast } from "react-toastify";
import Api from "../services/api";
import {
  cancelGameChallengeUrl,
  createChallengeUrl,
  endGameChallengeUrl,
  getGameChallengeUrl,
  getMinigamesLeaderboardUrl,
  getStakedChallengesUrl,
  getStakingAvailableChallengesUrl,
  getWinnerGameChallengeUrl,
  joinGameChallengeUrl,
  leaveGameChallengeUrl,
  sendGameChallengeUrlInvitationUrl,
  setReadyForChallengeUrl,
  stakeSpecificChallengeUrl,
  startGameChallengeUrl,
} from "./urls";

export const createGameChallengeApi = (body: object) => {
  return Api.request({
    publicApi: false,
    method: "post",
    url: createChallengeUrl,
    body,
  });
};

export const getGameChallengeApi = (id: string) => {
  return Api.request({
    method: "get",
    url: getGameChallengeUrl + `/${id}`,
    publicApi: false,
  });
};

export const startGameChallengeApi = (matchId: string) => {
  return Api.request({
    method: "post",
    url: startGameChallengeUrl,
    publicApi: false,
    body: {
      challengeId: matchId,
    },
  });
};

export const endGameChallengeApi = (matchId: string) => {
  return Api.request({
    method: "post",
    url: endGameChallengeUrl,
    publicApi: false,
    body: {
      challengeId: matchId,
    },
  });
};

export const cancelGameChallengeApi = (matchId: string) => {
  return Api.request({
    method: "post",
    url: cancelGameChallengeUrl,
    publicApi: false,
    body: {
      challengeId: matchId,
    },
  });
};

export const leaveGameChallengeApi = (matchId: string) =>
  Api.request({
    method: "post",
    url: leaveGameChallengeUrl,
    body: {
      challengeId: matchId,
    },
  });

export const sendGameChallengeUrlInvitationApi = (body: object) => {
  return Api.request({
    publicApi: false,
    method: "post",
    url: sendGameChallengeUrlInvitationUrl,
    body,
  });
};

export const joinGameChallengeApi = (matchId: string) => {
  return Api.request({
    publicApi: false,
    method: "post",
    url: joinGameChallengeUrl,
    body: {
      challengeId: matchId,
    },
  });
};

export const setReadyForChallengeApi = (matchId: string) => {
  return Api.request({
    method: "put",
    url: setReadyForChallengeUrl,
    publicApi: false,
    body: {
      challengeId: matchId,
    },
  });
};

export const getWinnerGameChallengeApi = (matchId: string) => {
  return Api.request({
    method: "post",
    url: getWinnerGameChallengeUrl,
    publicApi: false,
    body: {
      challengeId: matchId,
    },
  });
};

export const getChallengeLeaderboardApi = (data: Object) => {
  return Api.request({
    method: "get",
    url: getMinigamesLeaderboardUrl,
    publicApi: false,
    data,
  });
};

export const getStakeAvailableChallengesApi = (data: object) => {
  return Api.request({
    method: "get",
    url: getStakingAvailableChallengesUrl,
    data,
  });
};

export const stakeSpecificChallengeApi = (body: object) => {
  return Api.request({
    method: "post",
    url: stakeSpecificChallengeUrl,
    body,
  });
};

export const getStakedChallengesApi = () => {
  return Api.request({
    method: "get",
    url: getStakedChallengesUrl,
  });
};