import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  cancelGameChallengeApi,
  createGameChallengeApi,
  endGameChallengeApi,
  getChallengeLeaderboardApi,
  getGameChallengeApi,
  getWinnerGameChallengeApi,
  joinGameChallengeApi,
  leaveGameChallengeApi,
  sendGameChallengeUrlInvitationApi,
  setReadyForChallengeApi,
  startGameChallengeApi,
} from "api/miniGame.api";
interface WithCallback {
  payload?: any;
  matchId?: any;
  callback?: (data?: any) => void;
}

export const createGameChallenge = createAsyncThunk<any, WithCallback>(
  "minigame/createGameChallenge",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await createGameChallengeApi(payload);
      callback?.(data);
      return data;
    } catch (error: any) {
      toast.error(error.message);
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getGameChallenge = createAsyncThunk<any, WithCallback>(
  "minigame/getGameChallenge",
  async ({ matchId, callback }, { rejectWithValue }) => {
    try {
      const data: any = await getGameChallengeApi(matchId);
      callback?.(data);
      return data;
    } catch (error: any) {
      toast.error("Invalid Match ID");
      window.location.href = "/";
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const startGameChallenge = createAsyncThunk<any, WithCallback>(
  "minigame/startGameChallenge",
  async ({ matchId, callback }, { rejectWithValue }) => {
    try {
      const data: any = await startGameChallengeApi(matchId);
      callback?.();
      return data;
    } catch (error: any) {
      toast.error(error?.message);
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const endGameChallenge = createAsyncThunk<any, WithCallback>(
  "minigame/endGameChallenge",
  async ({ matchId, callback }, { rejectWithValue }) => {
    try {
      const data: any = await endGameChallengeApi(matchId);
      callback?.();
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const cancelGameChallenge = createAsyncThunk<any, WithCallback>(
  "minigame/cancelGameChallenge",
  async ({ matchId, callback }, { rejectWithValue }) => {
    try {
      const data: any = await cancelGameChallengeApi(matchId);
      callback?.();
      return data;
    } catch (error: any) {
      toast.error(error?.data.message);
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const leaveGameChallenge = createAsyncThunk<any, WithCallback>(
  "minigame/leaveGameChallenge",
  async ({ matchId, callback }, { rejectWithValue }) => {
    try {
      const data: any = await leaveGameChallengeApi(matchId);
      callback?.(data);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const sendGameChallengeUrlInvitation = createAsyncThunk<any, any>(
  "minigame/sendGameChallengeUrlInvitation",
  async (payload, { rejectWithValue }) => {
    try {
      const data: any = await sendGameChallengeUrlInvitationApi(payload);
      toast.success(data.message);
      return data;
    } catch (error: any) {
      toast.error(error.message);
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const joinGameChallenge = createAsyncThunk<any, WithCallback>(
  "minigame/joinGameChallenge",
  async ({ matchId, callback }, { rejectWithValue }) => {
    try {
      const data: any = await joinGameChallengeApi(matchId);
      callback?.(data);
      return data;
    } catch (error: any) {
      toast.error(error.data?.message);
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const setReadyForChallenge = createAsyncThunk<any, WithCallback>(
  "minigame/setReadyForChallenge",
  async ({ matchId, callback }, { rejectWithValue }) => {
    try {
      const data: any = await setReadyForChallengeApi(matchId);
      callback?.();
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getWinnerGameChallenge = createAsyncThunk<any, WithCallback>(
  "minigame/getGameChallenge",
  async ({ matchId, callback }, { rejectWithValue }) => {
    try {
      const data: any = await getWinnerGameChallengeApi(matchId);
      callback?.(data);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

export const getMinigamesLeaderBoard = createAsyncThunk<any, WithCallback>(
  "minigame/getMinigamesLeaderBoard",
  async ({ payload, callback }, { rejectWithValue }) => {
    try {
      const data: any = await getChallengeLeaderboardApi(payload);
      callback?.(data);
      return data;
    } catch (error: any) {
      if (!error.message) {
        throw error;
      }
      return rejectWithValue(error.message);
    }
  }
);

