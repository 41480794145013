import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { RootState } from "store/index";
import { setScreenStatus } from "store/currentMatch/currentMatchSlice";
import { setTournamentId } from "store/tournament/tournamentSlice";
import {
  setConsole,
  setDuelPath,
  setFromPresets,
  setJoiningMatchId,
  setMatchCubes,
  setMatchFromHome,
  setMatchSchedule,
  setSelectedGame,
  setSelectedGameImage,
  setSelectedGameType,
  setSelectedRound,
} from "store/matchMaking/matchMakingSlice";
import {
  setStakeFrom,
  setStakeMatch,
  setStakeTeam,
} from "store/stake/stakeSlice";
import { ReactComponent as Stream } from "assets/stream.svg";
import ImageHolder from "components/ImagePlaceholder";
import ConsolModal from "components/Consoles";
import LoadingAnim from "components/Loader";
import InAppStreamViewer from "components/InAppStreamViewer";

import CloseIcon from "assets/close-button.svg";
import AboutIcon from "assets/about.svg";
import StakesIcon from "assets/stakes.svg";
import { setLoginModalVisible } from "store/auth/authSlice";

const MoreEvents = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { eventType } = useParams();
  const { endedTournaments } = useAppSelector(
    (state: RootState) => state.tournament
  );
  const userData = useAppSelector((state: RootState) => state.user);
  const user = useAppSelector((state: RootState) => state.auth);
  const isFrom = useAppSelector((state: RootState) => state.stake).from;
  const isStackedBefore = useAppSelector((state: RootState) => state.user)?.user
    ?.isStackedBefore;
  const data = useAppSelector((state: RootState) => state.event);

  const [visibleStreaming, setVisibleStreaming] = useState(false);
  const [streamData, setStreamData] = useState<any>();
  const events = data.duels;
  const lives = data.lives;
  const tournaments = data.tournaments;

  const handleClose = () => {
    if (eventType === 'Staking-Events') return navigate('/wagering')
    if (isFrom === "Staked-Events") {
      navigate("/more-events/Staking-Events");
      dispatch(setStakeFrom("more-events"));
    } if (isFrom === "Tournaments") {
      navigate("/more-events/Tournaments");
      dispatch(setStakeFrom("Ended-Tournaments"));
    } else {
      navigate("/");
    }
  };

  const handlePress = (item: any) => {
    if (!user.userToken) return dispatch(setLoginModalVisible(true));
    const isHost = item.host === userData.user?._id;
    const isUserin =
      item.players.filter((player: any) => player === userData.user?._id)
        .length > 1;
    if (isHost) {
      dispatch(setDuelPath("DUEL"));
      dispatch(setScreenStatus("DUEL"));
      navigate(`/duels/lobby/${item._id}`);
      return;
    }
    if (isUserin) {
      dispatch(setDuelPath("JOIN_MATCH"));
      dispatch(setScreenStatus("JOIN_MATCH"));
      navigate(`/duels/lobby/${item._id}`);
      return;
    }
    dispatch(setSelectedGame(item.game));
    dispatch(setSelectedGameImage(item.coverImage));
    dispatch(setJoiningMatchId(item._id));
    dispatch(setDuelPath(item.matchType));
    dispatch(setMatchSchedule(item.matchStatus));
    dispatch(
      setSelectedGameType(
        item.game.gameTypes.find(
          (type: { type: string }) => type.type === item.gameType
        )
      )
    );
    dispatch(
      setSelectedRound(
        item.game.rounds.find(
          (round: { name: string }) => round.name === item.rounds
        )
      )
    );
    dispatch(setMatchCubes(item.cubeWager));
    dispatch(setMatchFromHome(true));
    dispatch(setFromPresets(false));
    dispatch(setScreenStatus("JOIN_MATCH"));
    dispatch(setDuelPath("JOIN_MATCH"));
    navigate("/duels/duel-overview");
  };
  const formatEventTime = (timestamp: string | number | Date) => {
    const date = new Date(timestamp);

    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };

    return date.toLocaleString(undefined, options);
  };
  const renderEvents = (item: any, index: number) => {
    enum GameTypes {
      ONE_VS_ONE = "ONE_VS_ONE",
      TWO_VS_TWO = "TWO_VS_TWO",
      THREE_VS_THREE = "THREE_VS_THREE",
      FOUR_VS_FOUR = "FOUR_VS_FOUR",
    }
    const AvailableSlots = (gameType: string) => {
      switch (gameType) {
        case GameTypes.ONE_VS_ONE:
          return 2;
        case GameTypes.TWO_VS_TWO:
          return 4;
        case GameTypes.THREE_VS_THREE:
          return 6;
        case GameTypes.FOUR_VS_FOUR:
          return 8;
        default:
          return 10;
      }
    };
    const participatingPlayers: number = item.players.length;
    const availableSlotsForMatch: number =
      AvailableSlots(item.gameType) - participatingPlayers;
    const isUserin =
      item.players.filter((player: any) => player === userData.user?._id)
        .length > 1;
    const isOngoing = item.onGoing;
    const highlight = isOngoing || isUserin ? "border-2 border-primary" : "";
    return (
      <button
        className={`flex flex-col p-2 m1 items-center w-[250px] h-[200px] bg-modalFade rounded-2xl m-5 ${highlight}`}
        key={index}
        onClick={() => handlePress(item)}
      >
        <span className="text font-bold text-[14px] ">{item.game.name}</span>
        <ImageHolder
          uri={item.coverImage}
          className=" rounded-2xl"
          width={200}
          height={200}
        />
        <div className="flex gap-5 mt-2 ">
          <span className="text  text-[9px] ">
            Available slots : {availableSlotsForMatch}
          </span>
          <span className="text  text-[9px] ">
            Entry fee : {item.cubeWager} $
          </span>
        </div>
        {item.matchStatus === "SCHEDULED" && item?.scheduledTime && (
          <span className="text-primary  text-[9px] ">
            On {formatEventTime(item?.scheduledTime)}
          </span>
        )}
      </button>
    );
  };

  const onPressInAppStream = (data: any) => {
    setStreamData(data);
    setVisibleStreaming(true);
  };

  const renderLives = (item: any, index: number) => {
    const handleClickLive = () => {
      const streamType = item.streamType;
      if (!user.userToken) return dispatch(setLoginModalVisible(true));
      switch (streamType) {
        case "IN_APP":
          onPressInAppStream(item);
          break;

        case "TWITCH":
          window.open(
            `https://www.twitch.tv/${item.twitch?.streamerId}`,
            "_blank"
          );
          break;
        default:
          break;
      }
    };

    const formatThumbnail = (url: string) => {
      if (!url) return "";
      return url.replace("{width}", "640").replace("{height}", "420");
    };
    return (
      <>
        {item?.isStreaming && (
          <div
            className="flex flex-col p-2 m1 items-center justify-center eve rounded-lg mx-1"
            key={index}
            onClick={handleClickLive}
          >
            <span className="text font-semibold mb-2 text-[9px] ">
              {item?.game?.name}
            </span>
            <ImageHolder
              uri={
                item.thumbnail
                  ? formatThumbnail(item.thumbnail)
                  : item.game?.image
              }
              width={180}
              height={180}
              className="min-w-[640] max-md:min-w-[128px] h-auto rounded-2xl"
            />
            <div className="flex flex-row gap-2 mt-1">
              <span className="text  text-[9px] truncate max-w-[200px]">
                {item?.streamTitle
                  ? item?.streamTitle
                  : `${item?.userId?.username} is stream with duel cube`}
              </span>
            </div>
          </div>
        )}
      </>
    );
  };

  const onClickTournament = (item: any) => {
    if (!user.userToken) return dispatch(setLoginModalVisible(true));
    const isHost = item?.host === userData.user?._id;
    if (isHost) {
      dispatch(setDuelPath("CREATE_TOURNAMENT"));
      dispatch(setScreenStatus("CREATE_TOURNAMENT"));
    } else {
      dispatch(setDuelPath("OPPONENT_JOIN"));
      dispatch(setScreenStatus("OPPONENT_JOIN"));
    }
    if (item.isUserIn) {
      dispatch(setTournamentId(item?._id));
      navigate(`/tournament/live-bracket/${item._id}`);
    } else {
      const tournamentId = item._id;
      navigate(`/duels/duel-overview/${tournamentId}`);
    }
  };
  const renderTournaments = (item: any, index: number) => {
    const isUserIn = item.isUserIn;
    const highlight = isUserIn ? "border-2 border-primary" : "";
    return (
      <button
        className={`flex flex-col p-2 m1 items-center justify-center eve rounded-lg mx-1 my-1 ${highlight}`}
        key={index}
        onClick={() => onClickTournament(item)}
      >
        <span className="text font-bold text-[14px] ">{item.game.name}</span>
        <ImageHolder
          uri={item.game.image}
          width={180}
          height={180}
          className=" rounded-2xl"
        />
        <div className="flex flex-row gap-2">
          <span className="text  text-[10px] ">{item.gameType}</span>
          <span className="text  text-[10px] ">{item.matchId}</span>
        </div>
        {item?.tournamentDate && (
          <span className="text-primary  text-[9px] ">
            On {formatEventTime(item?.tournamentDate)}
          </span>
        )}
      </button>
    );
  };

  const renderEndedTournaments = (item: any, index: number) => {
    return (
      <button
        className={`flex flex-col p-2 m1 items-center justify-center eve rounded-lg mx-1 my-1 `}
        key={index}
        onClick={() => {
          if (!user.userToken) return dispatch(setLoginModalVisible(true));
          navigate(`/tournament/summary/${item._id}`)
        }}
      >
        <ImageHolder uri={item.coverImage} width={180} height={180} />
        <div className="flex flex-col gap-1">
          <span className="text  text-[10px] ">{item.gameType}</span>
          <span className="text  text-[10px] ">{item.cubeWager} Cubes</span>
        </div>
      </button>
    );
  };

  const onTeam1StakeClick = (item: any) => {
    dispatch(setStakeTeam("ONE"));
    dispatch(setStakeMatch(item._id));
    dispatch(setStakeFrom("more-events"));
    if (!isStackedBefore) return navigate("/stakes/intro");
    navigate("/stakes/stake");
  };

  const onTeam2StakeClick = (item: any) => {
    dispatch(setStakeTeam("ONE"));
    dispatch(setStakeMatch(item._id));
    dispatch(setStakeFrom("more-events"));
    if (!isStackedBefore) return navigate("/stakes/intro");
    navigate("/stakes/stake");
  };

  const renderPlayers = (players: any[]) => {
    return (
      <div className="flex flex-wrap justify-between items-center">
        {players.map((player, index) => {
          const truncatedUsername =
            player.username.length > 8 ? `${player.username.slice(0, 8)}...` : player.username;

          return (
            <div key={index} className="m-1">
              <ImageHolder
                uri={player.profilePicture}
                className="w-[50px] h-[50px] rounded-full"
              />
              <span className="text-white text-[10px]">{truncatedUsername}</span>
            </div>
          );
        })}
      </div>
    );
  };


  const renderStakingEvents = (item: any, index: number) => {
    const isStreaming = item.streamerId;
    const isUserStaked = item?.stakes.some(
      (stake: any) => stake.user === userData.user?._id
    );
    return (
      <div
        className="flex flex-col bg-modalFade rounded-xl m-5 p-2 items-center"
        key={index}
      >
        <div className="flex flex-row items-center justify-start gap-8">
          {isStreaming && <Stream width={30} />}
          <span className="text flex text-[12px] justify-center">
            {item.game.name}
          </span>
          {isUserStaked && (
            <span className="text-primary flex text-[12px] justify-center">
              Staked
            </span>
          )}
        </div>
        <ImageHolder
          uri={item.coverImage}
          width={300}
          height={150}
          className=" my-2 rounded-2xl"
        />
        <div className="flex flex-row justify-between items-center">
          <div className="flex items-center justify-between gap-2">
            <button
              className={`border-2 rounded-xl w-28 h-12 flex flex-col items-center justify-center max-md:w-[22vw] max-md:rounded-md  ${isUserStaked
                ? " bg-disabled"
                : " bg-primary hover:bg-primaryActive"
                }`}
              onClick={() => onTeam1StakeClick(item)}
              disabled={isUserStaked || !user.userToken}
            >
              <span className="text-black text-sm max-md:text-xs">
                stake & win*
              </span>
              <span className="text-darkGreen font-bold max-md:text-xs">
                {isNaN(item?.team1StakeFactor)
                  ? "0"
                  : parseFloat(item?.team1StakeFactor?.toFixed(2))}{" "}
                x
              </span>
            </button>
          </div>
          <div className=" flex flex-col justify-start items-center px-3">
            <span className="text text-[10px] ">{item.gameType}</span>
            <span className="text text-[10px] ">{item.matchId}</span>
          </div>
          <div className="flex items-center">
            <button
              className={`border-2 rounded-xl w-28 h-12 flex flex-col items-center justify-center max-md:w-[22vw] max-md:rounded-md  ${isUserStaked
                ? " bg-disabled"
                : " bg-primary hover:bg-primaryActive"
                }`}
              onClick={() => onTeam2StakeClick(item)}
              disabled={isUserStaked || !user.userToken}
            >
              <span className="text-black text-sm max-md:text-xs">
                stake & win*
              </span>
              <span className="text-darkGreen font-bold max-md:text-xs">
                {isNaN(item?.team2StakeFactor)
                  ? "0"
                  : parseFloat(item?.team2StakeFactor?.toFixed(2))}{" "}
                x
              </span>
            </button>
          </div>
        </div>
        {/* render teams */}
        <div className="flex justify-between items-center">
          <div className="m-2">{renderPlayers(item.team1)}</div>
          <span className="text-white font-medium">VS</span>
          <div className="m-2">{renderPlayers(item.team2)}</div>
        </div>
      </div>
    );
  };

  const renderStakedEvents = (item: any, index: number) => {
    return (
      <div className="mx-auto w-full  bg-background bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex">
        <div
          className="flex flex-col bg-gray bg-modalFade rounded-xl m-5 p-4"
          key={index}
        >
          <span className="text flex text-[12px] justify-center">
            {item.game.name}
          </span>
          <ImageHolder
            uri={item.coverImage}
            width={300}
            height={150}
            className=" my-2 rounded-2xl"
          />

          <div className=" flex flex-col justify-start items-center px-3">
            <span className="text  text-[12px] ">{item.stakes[0]?.amount}</span>
            <span className="text text-[10px] ">{item.gameType}</span>
            <span className="text text-[10px] ">{item.matchId}</span>
          </div>

          {/* render teams */}
          <div className="flex justify-between items-center">
            <div className="m-2">{renderPlayers(item.team1)}</div>
            <span className="text-white font-medium">VS</span>
            <div className="m-2">{renderPlayers(item.team2)}</div>
          </div>
        </div>
      </div>
    );
  };

  const handleMyStakes = () => {
    if (!user.userToken) return dispatch(setLoginModalVisible(true));
    navigate("/more-events/Staked-Events");
    dispatch(setStakeFrom("Staked-Events"));
  };

  const checkType = () => {
    switch (eventType) {
      case "Events":
        return events.map(renderEvents);
      case "Lives":
        return lives?.map(renderLives);
      case "Tournaments":
        return tournaments.map(renderTournaments);
      // case "Staking-Events":
      //   return stakingEvents.map(renderStakingEvents);
      // case "Staked-Events":
      //   return stakedEvents.map(renderStakedEvents);
      case "Ended-Tournaments":
        return endedTournaments.map(renderEndedTournaments);
      default:
        break;
    }
  };

  const returnContentLength = () => {
    switch (eventType) {
      case "Events":
        if (events.length < 1) return "No Events";
        break;
      case "Lives":
        if (lives.length < 1) return "No Lives";
        break;
      case "Ended-Tournaments":
        if (endedTournaments.length < 1) return "No Tournaments";
        break;
      case "Tournaments":
        if (tournaments.length < 1) return "No Tournaments";
        break;
      // case "Staking-Events":
      //   if (stakingEvents.length < 1) return "No Matches Available for Staking";
      //   break;
      // case "Staked-Events":
      //   if (stakedEvents.length < 1) return "No Staked Matches";
        break;
      default:
        break;
    }
  };

  return (
    <div className="mx-auto w-full  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex   h-screen  p-2">
      <div className="container flex flex-col gap-y-[20px] md:my-16 max-md:pt-5 items-center ">
        <div
          className="  backdrop-blur-sm con rounded-[34px] p-10
        overflow-y-auto w-[calc(100%-176px)] min-h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)] justify-between flex flex-col  max-md:h-[95vh]"
        >
          <div className="w-full rounded-t-[34px] flex items-center justify-between">
            {eventType === "Staking-Events" || eventType === "Staked-Events" ? (
              <div className="flex md:absolute md:left-54 items-center gap-5 max-md:mx-5 max-md:justify-between max-md:mt-4">
                <button
                  onClick={() => {
                    navigate("/stakes/main");
                    dispatch(setStakeFrom("events"));
                  }}
                  className="bg-borderColor p-3 rounded-xl flex flex-row gap-4 items-center justify-center "
                >
                  <img src={AboutIcon} alt="about" className=" max-md:w-6" />
                  <span className="text-white text-xl max-md:text-sm">
                    What's staking?
                  </span>
                </button>
                <button
                  className="bg-borderColor p-3 rounded-xl border-0  flex flex-row gap-4 items-center justify-center "
                  onClick={handleMyStakes}
                >
                  <img src={StakesIcon} alt="stakes" className=" max-md:w-3" />
                  <span className="text-white text-xl max-md:text-sm">
                    My Stakes
                  </span>
                </button>
              </div>
            ) : (
              ""
            )}
            {eventType === "Tournaments" && (
              <div className="flex md:absolute md:left-54 items-center gap-5 max-md:mx-5 max-md:justify-between max-md:mt-4">
                <button
                  onClick={() => {
                    if (!user.userToken) return dispatch(setLoginModalVisible(true));
                    dispatch(setStakeFrom("Tournaments"));
                    navigate("/more-events/Ended-Tournaments");
                  }}
                  className="bg-borderColor p-3 rounded-xl flex flex-row gap-4 items-center justify-center "
                >
                  <span className="text-white text-xl max-md:text-sm">
                    Ended Tournaments
                  </span>
                </button>
              </div>
            )}
            <button className=" w-[25px] h-[25px] absolute right-10 max-md:w-[15px]"
              onClick={handleClose}>
              <img src={CloseIcon} alt="Close" />
            </button>
            <div className="w-full  flex items-center justify-center mt-4">
              <span className="text-2xl text-white items-center font-normal ">
                {eventType}
              </span>
            </div>
          </div>
          <div
            className={`overflow-x-auto flex flex-wrap md:mt-4 items-center h-full justify-center ${returnContentLength() ? "items-center h-full" : ""
              }`}
          >
            {checkType()}
            {returnContentLength() ? (
              <span className="text-lg text-center text-darkGray">
                - {returnContentLength()} -
              </span>
            ) : (
              ""
            )}
          </div>
        </div>
        <InAppStreamViewer
          visible={visibleStreaming}
          streamRoomId={streamData?.inApp?.streamRoomId}
          streamToken={streamData?.inApp?.streamToken}
          username={streamData?.userId?.username}
          profilePicture={streamData?.userId?.profilePicture}
          onClose={() => setVisibleStreaming(false)}
        />
        <LoadingAnim loading={data.loader} />
      </div>
    </div>
  );
};

export default MoreEvents;
