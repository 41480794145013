import React, { useEffect, useState } from 'react';
import CloseIcon from "assets/close-button.svg";
import AboutIcon from "assets/about.svg";
import StakesIcon from "assets/stakes.svg";
import BackIcon from "assets/arrow-right.svg";
import { Pagination, ThemeProvider, createTheme } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getStakeAvailableChallenges, getStakeAvailableDuels, getStakeAvailableTournaments, getStakedChallenges, getStakedMatches, getStakedTournaments } from 'store/stake/action';
import ImageHolder from 'components/ImagePlaceholder';
import { ReactComponent as Stream } from "assets/stream.svg";
import { RootState } from 'store/index';
import { setStakeFrom, setStakeMatch, setStakeMethod, setStakePlayer, setStakeTeam } from 'store/stake/stakeSlice';
import { useNavigate } from 'react-router-dom';
import miscsStyles from "styles/miscs/miscs.module.scss";
import LoadingAnim from 'components/Loader';
import { setLoginModalVisible } from 'store/auth/authSlice';
import { games } from 'types/games';

enum tabs {
    TOURNAMENTS = 'TOURNAMENTS',
    DUELS = 'DUELS',
    CHALLENGES = 'CHALLENGES',
}

const MyStakesScreen = () => {
    const [tab, setTab] = useState(tabs.DUELS);
    const [tabData, setTabData] = useState<any[]>([]);
    const [totalPages, setTotalPages] = useState<number>(0);
    const userData = useAppSelector((state: RootState) => state.user);
    const auth = useAppSelector((state: RootState) => state.auth);
    const isStackedBefore = userData.user?.isStackedBefore;
    const navigate = useNavigate();

    const { duels, challenges, tournaments, loading } = useAppSelector(
        (state) => state.stake
    );
    const dispatch = useAppDispatch();
    const handleClose = () => {
        return navigate('/stakes/available')
    }
    const onBack = () => {
        return navigate('/stakes/available')
    }

    const darkTheme = createTheme({
        palette: {
            mode: "dark",
        },
    });

    const setData = () => {
        switch (tab) {
            case tabs.DUELS:
                setTabData(duels.stakedDuels);
                setTotalPages(duels.stakedDuelsTotalPages)
                break;
            case tabs.TOURNAMENTS:
                setTabData(tournaments.stakedTournaments)
                setTotalPages(tournaments.stakedTournamentsTotalPages)
                break;
            case tabs.CHALLENGES:
                setTabData(challenges.stakedChallenges)
                setTotalPages(challenges.stakedChallengesTotalPages)
                break;

            default:
                break;
        }
    }

    const fetchData = (page: number) => {
        switch (tab) {
            case tabs.DUELS:
                dispatch(getStakedMatches({ payload: { page }, callback: setData }))

                break;

            case tabs.CHALLENGES:
                dispatch(getStakedChallenges({ payload: { page }, callback: setData }))
                setData();
                break;

            case tabs.TOURNAMENTS:
                dispatch(getStakedTournaments({ payload: { page }, callback: setData }))
                setData();
                break;
        }
    }

    useEffect(() => {
        fetchData(1);
    }, [tab])

    const getButtonTextStyle = (tabName: string) => {
        return tab === tabName ? 'text-primary' : 'text-white';
    };

    // Staking Duels
    const renderDuels = (item: any, index: number) => {

        const isStreaming = item.streamerId;
        const isUserStaked = item?.stakes?.some(
            (stake: any) => stake.user === userData.user?._id
        );

        const renderPlayers = (players: any[]) => {
            return (
                <div className="flex flex-wrap justify-between items-center">
                    {players?.map((player, index) => {
                        const truncatedUsername =
                            player?.username?.length > 8 ? `${player.username.slice(0, 8)}...` : player?.username;

                        return (
                            <div key={index} className="m-1">
                                <ImageHolder
                                    uri={player.profilePicture}
                                    className="w-[50px] h-[50px] rounded-full"
                                />
                                <span className="text-white text-[10px]">{truncatedUsername}</span>
                            </div>
                        );
                    })}
                </div>
            );
        };


        return (
            <div
                className="flex flex-col bg-modalFade rounded-xl m-5 p-2 items-center gap-4"
                key={index}
            >
                <div className="flex flex-row ">
                    <div className="flex flex-row items-center justify-start gap-8">
                        {isStreaming && <Stream width={30} />}
                        <span className="text flex text-[12px] justify-center">
                            {item?.game?.name}
                        </span>
                        {isUserStaked && (
                            <span className="text-primary flex text-[12px] justify-center">
                                Staked
                            </span>
                        )}
                    </div>
                </div>
                <div className="flex flex-row gap-4 max-md:flex-col">
                    <ImageHolder
                        uri={item.coverImage}
                        width={250}
                        height={150}
                        className=" my-2 rounded-2xl"
                    />
                    <div className="flex flex-col justify-between items-center">

                        <div className="flex flex-row justify-between items-center gap-2">
                            <div className="flex items-center justify-between gap-2">
                                <button
                                    className={`border-2 rounded-xl w-28 h-12 flex flex-col items-center justify-center max-md:w-[22vw] max-md:rounded-md  ${isUserStaked
                                        ? " bg-disabled"
                                        : " bg-primary hover:bg-primaryActive"
                                        }`}

                                    disabled={true}
                                >
                                    <span className="text-black text-sm max-md:text-xs">
                                        stake & win*
                                    </span>
                                    <span className="text-darkGreen font-bold max-md:text-xs">
                                        {isNaN(item?.team1StakeFactor)
                                            ? "0"
                                            : parseFloat(item?.team1StakeFactor?.toFixed(2))}{" "}
                                        x
                                    </span>
                                </button>
                            </div>
                            <div className="flex items-center">
                                <button
                                    className={`border-2 rounded-xl w-28 h-12 flex flex-col items-center justify-center max-md:w-[22vw] max-md:rounded-md  ${isUserStaked
                                        ? " bg-disabled"
                                        : " bg-primary hover:bg-primaryActive"
                                        }`}
                                    disabled={true}
                                >
                                    <span className="text-black text-sm max-md:text-xs">
                                        stake & win*
                                    </span>
                                    <span className="text-darkGreen font-bold max-md:text-xs">
                                        {isNaN(item?.team2StakeFactor)
                                            ? "0"
                                            : parseFloat(item?.team2StakeFactor?.toFixed(2))}{" "}
                                        x
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div className="flex flex-col justify-between items-center">
                            <div className=" flex flex-col justify-start items-center px-3">
                                <span className="text text-[10px] ">{item.gameType}</span>
                                <span className="text text-[10px] ">{item.matchId}</span>
                            </div>

                        </div>
                        {/* render teams */}
                        <div className="flex justify-between items-center">
                            <div className="m-2">{renderPlayers(item.team1)}</div>
                            <span className="text-white font-medium">VS</span>
                            <div className="m-2">{renderPlayers(item.team2)}</div>
                        </div>
                    </div>
                </div>

            </div>
        );

    };
    // Staking Tournaments
    const renderTournaments = (item: any, index: number) => {
        const isUserStaked = item?.stakes?.some(
            (stake: any) => stake.user === userData.user?._id
        );

        const renderPlayers = (player: any, index: number) => {
            const filterPlayer = item?.players?.find((user: any) => user.user._id === player);
            return (
                <div className="flex flex-wrap justify-between items-center" key={index}>
                    <div className="m-1">
                        <ImageHolder
                            uri={filterPlayer?.user.profilePicture}
                            className="w-[40px] h-[40px] rounded-full"
                        />
                        <span className="text-white text-[10px]">{filterPlayer?.user.username}</span>
                    </div>
                </div>
            );
        };

        const renderTeam = (team: any, index: number) => {
            return (
                <div className='flex flex-row items-center justify-start gap-2 p-1 border-2 border-placeholder rounded-xl'>
                    <div className="flex items-center justify-between gap-2">
                        <button
                            className={`border-2 rounded-xl w-28 h-12 flex flex-col items-center justify-center max-md:w-[22vw] max-md:rounded-md  ${isUserStaked
                                ? " bg-disabled"
                                : " bg-primary hover:bg-primaryActive"
                                }`}
                            disabled
                        >
                            <span className="text-black text-sm max-md:text-xs">
                                stake & win*
                            </span>
                            <span className="text-darkGreen font-bold max-md:text-xs">
                                {isNaN(team?.stakeFactor)
                                    ? "0"
                                    : parseFloat(team?.stakeFactor?.toFixed(2))}{" "}
                                x
                            </span>
                        </button>
                    </div>
                    <div className='flex flex-row' key={index}>
                        {team?.players?.map(renderPlayers)}
                    </div>
                </div>)
        }


        return (
            <div
                className="flex flex-col bg-modalFade rounded-xl  p-2 items-center  gap-4"
                key={index}
            >
                <div className="flex flex-row gap-4 max-md:flex-col ">
                    <div className=' flex flex-col items-center justify-center'>
                        <ImageHolder
                            uri={item.coverImage}
                            width={250}
                            height={150}
                            className=" my-2 rounded-2xl"
                        />
                        <div className=" flex flex-col justify-start items-center px-3 gap-2">
                            <span className="text text-[10px] ">{item.gameType}</span>
                            <span className="text text-[10px] ">{item.tournamentId}</span>
                        </div>
                    </div>
                    <div className="flex flex-col justify-between items-center">
                        <div className="flex flex-col justify-between items-center gap-2">
                            {item.teams?.map(renderTeam)}
                        </div>
                    </div>
                </div>

            </div>
        );
    }
    // Staking Challenge
    const renderChallenges = (item: any, index: number) => {
        const handleFilterCoverImage = () => {
            const selectedGame = games.find((game) => game?.id === item?.gameId._id);
            return selectedGame?.image;
        };
        const team1 = item.players.filter((player: any) => player === item.host);
        const team2 = item.players.filter((player: any) => player !== item.host);
        const isUserStaked = item?.stakes?.some(
            (stake: any) => stake.user === userData.user?._id
        );
        const getPlayerStakedFactor = (player: string) => {
            return item.playersStaked.find((item: any) => item.player === player)?.stakeFactor;
        }


        const renderPlayers = (players: any[]) => {
            return (
                <div className="flex flex-wrap justify-between items-center">
                    {players?.map((player, index) => {
                        const truncatedUsername =
                            player?.username?.length > 8 ? `${player?.username.slice(0, 8)}` : player?.username;

                        return (
                            <div key={index} className="m-1 flex flex-col items-center gap-4">
                                <ImageHolder
                                    uri={player.profilePicture}
                                    className="w-[50px] h-[50px] rounded-full"
                                />
                                <span className="text-white text-[10px]">{truncatedUsername}</span>
                            </div>
                        );
                    })}
                </div>
            );
        };


        return (
            <div
                className="flex flex-col bg-modalFade rounded-xl m-5 p-2 items-center gap-4"
                key={index}
            >
                <div className="flex flex-row ">
                    <div className="flex flex-row items-center justify-start gap-8">
                        <span className="text flex text-[12px] justify-center">
                            {item?.gameId?.name}
                        </span>
                        {isUserStaked && (
                            <span className="text-primary flex text-[12px] justify-center">
                                Staked
                            </span>
                        )}
                    </div>
                </div>
                <div className="flex flex-row gap-4 max-md:flex-col">
                    <img
                        className="md:h-[30vh] rounded-xl"
                        src={handleFilterCoverImage()}
                        alt="Game Banner"
                    />
                    <div className="flex flex-col items-center">

                        <div className="flex flex-row justify-between items-center gap-2">
                            <div className="flex items-center justify-between gap-2">
                                <button
                                    className={`border-2 rounded-xl w-28 h-12 flex flex-col items-center justify-center max-md:w-[22vw] max-md:rounded-md  ${isUserStaked
                                        ? " bg-disabled"
                                        : " bg-primary hover:bg-primaryActive"
                                        }`}
                                    disabled
                                >
                                    <span className="text-black text-sm max-md:text-xs">
                                        stake & win*
                                    </span>
                                    <span className="text-darkGreen font-bold max-md:text-xs">
                                        {isNaN(getPlayerStakedFactor(team1[0]?._id))
                                            ? "0"
                                            : parseFloat(getPlayerStakedFactor(team1[0]?._id).toFixed(2))}{" "}
                                        x
                                    </span>
                                </button>
                            </div>
                            <div className="flex items-center">
                                <button
                                    className={`border-2 rounded-xl w-28 h-12 flex flex-col items-center justify-center max-md:w-[22vw] max-md:rounded-md  ${isUserStaked
                                        ? " bg-disabled"
                                        : " bg-primary hover:bg-primaryActive"
                                        }`}
                                    disabled
                                >
                                    <span className="text-black text-sm max-md:text-xs">
                                        stake & win*
                                    </span>
                                    <span className="text-darkGreen font-bold max-md:text-xs">
                                        {isNaN(getPlayerStakedFactor(team2[0]?._id))
                                            ? "0"
                                            : parseFloat(getPlayerStakedFactor(team2[0]?._id))}{" "}
                                        x
                                    </span>
                                </button>
                            </div>
                        </div>

                        {/* render teams */}
                        <div className="flex justify-between items-center">
                            <div className="m-2">{renderPlayers(team1)}</div>
                            <span className="text-white font-medium">VS</span>
                            <div className="m-2">{renderPlayers(team2)}</div>
                        </div>
                        <div className="flex flex-col justify-between items-center">
                            <div className=" flex flex-col justify-start items-center px-3">
                                <span className="text text-[10px] ">Game Type : {item.challengeType}</span>
                                <span className="text text-[10px] ">Game Id : {item.challengeId}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

    };

    const handlePageChange = (event: any, value: number) => {
        fetchData(value)// fetch data when page change
    }

    return (
        <>
            <div className="mx-auto w-screen  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex   h-screen  md:p-2">
                <div className="container flex flex-col gap-y-[20px] md:my-16 max-md:pt-5 items-center ">
                    <div
                        className="  backdrop-blur-sm con rounded-[34px] p-10 max-md:p-0
        overflow-y-auto w-[calc(100%-176px)] min-h-[calc(100vh-160px)] max-md:w-full gap-6 max-md:gap-6 flex flex-col items-center  max-md:h-[95vh]"
                    >
                        {/* Header */}
                        <div className="w-full max-md:w-[80vw] rounded-t-[34px] flex items-center justify-between max-md:mt-4">
                            <div className="flex flex-row items-center gap-5">
                                <button className="flex rotate-180 w-[25px] h-[25px]" onClick={onBack}>
                                    <img src={BackIcon} alt="Close" />
                                </button>
                                <span className="text-3xl text-white items-center font-normal max-md:text-xl">
                                    My Stakes
                                </span>
                            </div>
                            <button className=" w-[25px] h-[25px]" onClick={handleClose}>
                                <img src={CloseIcon} alt="Close" />
                            </button>
                        </div>
                        {/* Tab-Buttons */}
                        <div className="flex flex-row items-center  max-md:mx-5 justify-between max-md:justify-evenly md:gap-16 max-md:mt-4 border-2 border-darkenGray w-full max-md:w-[80vw]">
                            <button
                                className="md:p-4 rounded-xl border-0  flex flex-row gap-4 items-center justify-center md:w-[250px]"
                                onClick={() => setTab(tabs.DUELS)}
                            >
                                <span className={`text-xl max-md:text-sm ${getButtonTextStyle(tabs.DUELS)}`}>
                                    {tabs.DUELS}
                                </span>
                            </button>
                            <div className='h-10 w-0.5 bg-darkenGray' />
                            <button
                                className="md:p-4 rounded-xl border-0  flex flex-row gap-4 items-center justify-center md:w-[250px]"
                                onClick={() => setTab(tabs.TOURNAMENTS)}
                            >
                                <span className={`text-xl max-md:text-sm ${getButtonTextStyle(tabs.TOURNAMENTS)}`}>
                                    {tabs.TOURNAMENTS}
                                </span>
                            </button>
                            <div className='h-10 w-0.5 bg-darkenGray' />
                            <button
                                className="md:p-4 rounded-xl border-0  flex flex-row gap-4 items-center justify-center md:w-[250px]"
                                onClick={() => setTab(tabs.CHALLENGES)}
                            >
                                <span className={`text-xl max-md:text-sm ${getButtonTextStyle(tabs.CHALLENGES)}`}>
                                    {tabs.CHALLENGES}
                                </span>
                            </button>
                        </div>
                        <div className={`w-full md:max-h-[calc(90vh-430px)] mt-5 grid desktop:grid-cols-2 grid-cols-1 gap-x-[40px] gap-y-[10px] flex-wrap overflow-y-auto justify-center p-1 max-md:max-h-2/3 mb-10 ${miscsStyles.beautifulScrollbarHidden}`}>
                            {tab === tabs.DUELS ?
                                duels?.stakedDuels?.map(renderDuels) : tab === tabs.TOURNAMENTS ? tournaments?.stakedTournaments?.map(renderTournaments) : challenges?.stakedChallenges?.map(renderChallenges)}
                        </div>
                        <div className='flex flex-row items-start'>
                            {tabData.length === 0 && <span className="text-lg text-center text-darkGray">- No data -</span>}
                        </div>
                    </div>
                </div>
                <div className="flex absolute justify-center bottom-5 left-0 right-0 w-full">
                    <ThemeProvider theme={darkTheme}>
                        <Pagination
                            count={totalPages}
                            variant="outlined"
                            shape="rounded"
                            color="standard"
                            onChange={handlePageChange}
                        />
                    </ThemeProvider>
                </div>
                <LoadingAnim loading={loading} />
            </div>
        </>
    );

}

export default MyStakesScreen;

