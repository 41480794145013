import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AppDispatch, RootState } from "store/index";
import {
  addCommunity,
  editCommunity,
  getCommunityList,
} from "store/community/action";
import {
  resetPage,
  setCommunitiesDescription,
  setCommunityState,
} from "store/community/communitySlice";
import CloseIcon from "assets/close-button.svg";
import ProgressLines from "components/ProgressLines";
import ButtonDc from "components/Button";
import Loader from "components/Loader";

export default function AddDescriptionScreen({ }) {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  const {
    communitiesName,
    communitiesType,
    adult,
    communitiesTitle,
    communityState,
    coverImage,
    editCommunityId,
    description,
    loader,
  } = useAppSelector((state: RootState) => state.community);

  const [communityDescription, setCommunityDescription] = useState(
    description ? description : ""
  );

  const onCancel = () => {
    navigate("/communication/main");
  };

  const handleDescriptionChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setCommunityDescription(e.target.value);
  };

  const createCommunity = () => {

    dispatch(setCommunitiesDescription(communityDescription));
    dispatch(
      addCommunity({
        payload: {
          name: communitiesName,
          type: communitiesType,
          adult: adult,
          title: communitiesTitle,
          description: communityDescription,
          image: coverImage,
        },
        callback: () => {
          dispatch(resetPage());
          setTimeout(() => {
            navigate("/communication/invite-to-community");
            dispatch(getCommunityList(1));
          }, 3000);
        }
      })
    );

  };
  const editedCommunity = async () => {
    await dispatch(
      editCommunity({
        payload: {
          query: `/${editCommunityId}`,
          data: {
            name: communitiesName,
            type: communitiesType,
            adult: adult,
            title: communitiesTitle,
            description: communityDescription,
            image: coverImage,
          },
        },
        callback: (response: any) => {
          dispatch(resetPage());
          setTimeout(() => {
            dispatch(getCommunityList(1));
          }, 3000);
        },
      })
    );
    navigate("/communication/main");
    dispatch(setCommunityState(""));
  };

  const onCreateCommunityPressed = () => {
    communityState === "EDIT" ? editedCommunity() : createCommunity();
  };

  return (
    <div className="mx-auto w-full  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex flex-col  h-screen ">
      <div className="container flex flex-col gap-y-[20px] md:my-16 max-md:p-5 items-center">
        <div
          className="  backdrop-blur-sm con rounded-[34px] p-10
        overflow-y-auto w-[calc(100%-176px)] h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)]  max-md:h-[95vh]"
        >
          <div className="flex flex-col gap-[1em]">
            <div className="w-full rounded-t-[34px] flex items-center justify-between">
              <div className="flex flex-row gap-4 items-center justify-center">
                <span className="text-3xl text-white items-center font-normal max-md:text-xl">
                  The {communitiesName} Community
                </span>
              </div>
              <button
                className=" w-[25px] h-[25px] absolute right-10"
                onClick={onCancel}
              >
                <img src={CloseIcon} alt="Close" />
              </button>
            </div>
          </div>

          <div className="flex flex-col w-full justify-center my-10 items-center gap-4">
            <div className="md:h-[500px] flex flex-col w-full items-center gap-y-8">
              <span className=" text-white text-center md:w-[420px] w-full text-lg md:text-xl">
                Add Description
              </span>
              <span className=" text-white text-center md:w-[420px] w-full text-sm md:text-lg">
                Describe what your duel community is about.
              </span>
              <div className="flex  flex-col">
                <textarea
                  className=" bg-borderColor align-top gap-5 h-36 w-[70vw] md:w-[490px] rounded-xl p-2 outline-none text-left text-white"
                  placeholder="Describe your Duel Group...."
                  value={communityDescription}
                  onChange={handleDescriptionChange}
                  maxLength={250}
                />
                <span className="flex text-xs justify-end text-white">
                  Max 250
                </span>
              </div>
            </div>

            <div className="px-10 w-full flex flex-col justify-center max-md:absolute bottom-10 max-md:flex-col">
              <ProgressLines count={4} selected={3} />
              <div className="flex flex-row justify-center gap-10 mt-5 max-md:flex-col">
                <div>
                  <ButtonDc
                    text={
                      communityState === "EDIT"
                        ? "Edit Community"
                        : "Create Community"
                    }
                    short
                    type={"primary"}
                    action={() => onCreateCommunityPressed()}
                    disabled={!communityDescription}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Loader loading={loader} />
    </div>
  );
}
