import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { RootState } from "store/index";
import { toast } from "react-toastify";
import { capturePayment } from "store/wallet/action";
import WinnerFrame from "assets/won.png";
import LostFrame from "assets/lost.png";
import WinnerBg from "assets/winner-bg.svg";
import ImageHolder from "components/ImagePlaceholder";
import PaymentMethod from "components/PaymentMethod";
import LoadingAnim from "components/Loader";
import ButtonDc from "components/Button";

const DuelWonScreen = () => {
  const navigate = useNavigate();
  const { matchId } = useParams();

  const liveBracket = useAppSelector((state: RootState) => state.liveBrackets);
  const liveBracketMatches = liveBracket?.tournamentInfo.matchList?.filter(
    (match) => match.stage === liveBracket.tournamentInfo.currentStage
  );

  const lastStage = liveBracketMatches[liveBracketMatches.length - 1];
  const isThisLastMatch = lastStage?.matchNumber === 1;

  const { selectedDuelPath } = useAppSelector((state) => state.matchMaking);
  const isTournament =
    selectedDuelPath === "CREATE_TOURNAMENT" ||
    selectedDuelPath === "OPPONENT_JOIN_MATCH" ||
    selectedDuelPath === "OPPONENT_JOIN";

  const userProfile = useAppSelector((state: RootState) => state.user).user?.profilePicture;

  const currentMatch = useAppSelector(
    (state: RootState) => state.currentMatch
  ).currentMatch;
  const matchMaking = useAppSelector(
    (state: RootState) => state.matchMaking
  ).backgroundMatch;
  const cubeWager = matchMaking?.cubeWager ? matchMaking?.cubeWager : currentMatch.cubeWager;

  const isLoading = useAppSelector((state) => state.wallet)?.loader;

  const [isWinner, setIsWinner] = useState(true);
  const [readyToPay, setReadyToPay] = useState(false);
  const dispatch = useAppDispatch();

  const userId = useAppSelector((state: RootState) => state.user).user?._id;
  const players = currentMatch?.players;
  const isUserInTheMatch = players.find((item: any) => item._id === userId) ? true : false

  useEffect(() => {
    if (!isUserInTheMatch) {
      toast.warning("The match you are looking for is ended.")
      navigate('/');
    }
  }, [isUserInTheMatch])

  const handlePaymentSuccess = () => {
    setReadyToPay(false);
    toast.success("Payment successful.");
    navigate(`/duels/payment-status/paid/${matchId}?payment=success`);
  };

  const handlePay = () => {
    if (cubeWager === 0) {
      dispatch(
        capturePayment({
          payload: {
            orderId: false,
            paymentId: matchId,
          },
          callback: () => navigate(`/duels/payment-status/${matchId}?payment=success`),
        })
      );
    } else {
      if (!isTournament || isThisLastMatch) return setReadyToPay(true);
      if (!isThisLastMatch)
        return navigate(`/duels/payment-status/${matchId}?payment=success`);
    }
  };

  const onPayPaypal = (token: any) => {
    dispatch(
      capturePayment({
        payload: {
          orderId: token,
          paymentId: token,
        },
        callback: handlePaymentSuccess,
      })
    );
  };

  useEffect(() => {
    const token = window.location?.search
      ?.split("&token=")?.[1]
      ?.split("&")?.[0];
    if (!token) return;
    onPayPaypal(token);
  }, []);

  useEffect(() => {
    window.onbeforeunload = () => {
      return "";
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, []);
  //TODO Get winner
  // useEffect(() => {
  //   // if (from != 'inMatchView') {
  //   //   return setIsWinner(userWon);
  //   // }
  //   switch (currentMatch?.winner) {
  //     case 0:
  //       setIsWinner(false);
  //       break;
  //     // navigation.navigate(DUEL_WON_SCREEN);
  //     case 1:
  //       if (team1?.find((player) => player?._id == userId)) {
  //         setIsWinner(true);
  //         break;
  //       }
  //       setIsWinner(false);
  //       break;
  //     case 2:
  //       if (team2?.find((player) => player?._id == userId)) {
  //         setIsWinner(true);
  //         break;
  //       }
  //       setIsWinner(false);
  //       break;
  //     default:
  //       setIsWinner(false);
  //       break;
  //   }
  // }, [currentMatch?.winner, team1, team2]);

  return (
    <div className="mx-auto w-full bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex min-h-screen h-full">
      <LoadingAnim loading={isLoading} />
      {readyToPay && (
        <PaymentMethod
          amount={cubeWager || 0}
          payId={matchId}
          onSuccess={handlePaymentSuccess}
          from="DUEL_WON"
        />
      )}
      <div className="container flex  flex-col gap-y-[20px] md:my-16 items-center justify-center max-md:p-5">
        <div className=" flex flex-col  gap-y-[20px] md:mb-16 items-center justify-center max-md:mt-24">
          {isWinner && <img src={WinnerBg} alt="" className="absolute " />}
          <div className="flex flex-col  items-center justify-center gap-5 mt-16 ">
            <div className="items-center justify-center translate-y-[-10px]">
              <img src={isWinner ? WinnerFrame : LostFrame} alt="" />
              <ImageHolder
                uri={userProfile}
                className="absolute w-[170px] h-[170px] rounded-full top-[22px] left-[87px]"
              />
            </div>
            <span className="text-5xl text-white items-center font-normal max-md:text-3xl">
              {isWinner ? "CONGRATULATIONS!" : "LOST!"}
            </span>
          </div>
          <div className="flex flex-col justify-center items-center gap-y-6">
            <div className="justify-center items-center  text-center">
              <span className="text-2xl text-white items-center font-normal text-center max-md:text-xl">
                {isWinner ? "YOU ARE THE CHAMPION!" : "BETTER LUCK NEXT TIME!"}
              </span>
            </div>
          </div>
        </div>
        <ButtonDc
          text="Next"
          action={handlePay}
          type="primary"
          className="relative z-100"
        />
      </div>
    </div>
  );
};

export default DuelWonScreen;
