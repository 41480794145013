import React, { useState } from "react";
import CloseIcon from "assets/close-button.svg";
import { useNavigate } from "react-router-dom";
import Carousel from "nuka-carousel";
import ButtonDc from "components/Button";
import Intro01 from "assets/stakes/intro01.png";
import Intro02 from "assets/stakes/intro02.png";
import Intro03 from "assets/stakes/intro03.png";
import Intro04 from "assets/stakes/intro04.png";
import Intro05 from "assets/stakes/intro05.png";
import Intro06 from "assets/stakes/intro06.png";
import { useAppSelector } from "store/hooks";
import { RootState } from "store/index";

interface ItemProps {
  icon: string;
  name: string;
  text1: string;
  text2?: string;
  lastStage: boolean;
}
const itemsData: ItemProps[] = [
  {
    icon: Intro01,
    name: "How it works",
    text1:
      "All teams start at the same returns. pick a team. Get paid if they win.",
    lastStage: false,
  },
  {
    icon: Intro02,
    name: "Watch The Returns",
    text1:
      "As more money gets stacked on an individual team, the returns go down.",
    text2: "Returns go up as more money gets staked on other teams",
    lastStage: false,
  },
  {
    icon: Intro03,
    name: "Pick Your Team",
    text1: "Pick the team you think will win",
    text2:
      "Depending on the event you can get paid out if your team places 1st, 2nd or 3rd.",
    lastStage: false,
  },
  {
    icon: Intro04,
    name: "Enter Amount",
    text1:
      "Choose how much you want to stake your team for. The more you stake the more you can win.",
    lastStage: false,
  },
  {
    icon: Intro05,
    name: "Return Can Change",
    text1: "Your stakes return does not lock until the event starts.",
    text2: "Once your stake is placed, you cannot undo it.",
    lastStage: false,
  },
  {
    icon: Intro06,
    name: "Win Cash!",
    text1:
      "If your team wins you win! Once the results are reviewed & certified your winnings will be added to your wallet instantly!",
    lastStage: true,
  },
];

export default function StakingIntroScreen({}) {
  const navigate = useNavigate();
  const [sliderIndex, setSliderIndex] = useState(0);
  const isMobile = window.innerWidth <= 768;
  const isFrom = useAppSelector((state: RootState) => state.stake).from;

  const onCancel = () => {
    navigate("/more-events/Staking-Events");
  };

  const handleDonePress = () => {
    if (isFrom === "events") return navigate("/stakes/available");
    navigate("/stakes/stake-amount");
  };

  const handleNextSlide = () => {
    if (sliderIndex < itemsData.length - 1) {
      setSliderIndex(sliderIndex + 1);
    } else {
      handleDonePress();
    }
  };

  const handlePreviousSlide = () => {
    if (sliderIndex > 0) {
      setSliderIndex(sliderIndex - 1);
    }
  };

  const Slider = () => {
    const info = {
      slidesToShow: 1,
      wrapAround: true,
      autoplay: false,
      autoplayInterval: 3000,
      slideIndex: sliderIndex,
    };

    return (
      <Carousel
        {...info}
        defaultControlsConfig={{
          pagingDotsStyle: {
            fill: "#FFD482",
            marginRight: "10px",
            position: "relative",
            bottom: isMobile ? "-5vh" : "10vh",
          },
        }}
        renderCenterLeftControls={({ previousSlide }) => null}
        renderCenterRightControls={({ nextSlide }) => null}
      >
        {itemsData.map((item, index) => (
          <div key={index} className="flex justify-center">
            <Item {...item} />
          </div>
        ))}
      </Carousel>
    );

    function Item({ icon, name, text1, text2, lastStage }: ItemProps) {
      return (
        <div className="md:h-[60vh] flex mt-10">
          <div className="w-[234px] md:w-[509px]  h-[380px]">
            <div className="flex flex-row gap-3 justify-center mt-4 md:h-[20vh]">
              <img src={icon} alt="icon" />
            </div>
            <div className="flex flex-col items-center gap-4 mt-4 md:px-16">
              <span className="flex text-center md:line-clamp-4 max-md:text-[18px] text-primary text-2xl">
                {name}
              </span>
              <span className="flex text-center md:line-clamp-4 max-md:text-[12px] text-subText">
                {text1}
              </span>
              {text2 && (
                <span className="flex text-center md:line-clamp-4 max-md:text-[12px] text-subText">
                  {text2}
                </span>
              )}
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="mx-auto w-full flex-col bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex items-center  h-screen ">
      <div className="container flex flex-col gap-y-[20px] md:my-16 max-md:p-5 items-center justify-between">
        <div
          className="  backdrop-blur-sm con rounded-[34px] p-10 w-[calc(100%-176px)] 
        h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)] max-md:h-[95vh]"
        >
          <div className="w-full rounded-t-[34px] flex items-center justify-between">
            <span className="text-3xl text-white items-center font-normal">
              What’s Staking
            </span>
            <button className="w-[25px] h-[25px]" onClick={onCancel}>
              <img src={CloseIcon} alt="Close" />
            </button>
          </div>
          <div className="flex flex-col items-center gap-[5em] justify-center max-md:flex-row">
            <div className="w-[65%] max-md:w-[90%]">
              <Slider />
              <div className="flex flex-row items-center gap-5 absolute bottom-20 md:w-[calc(70%-176px)] max-md:w-[70%] max-md:bottom-5 max-md:gap">
                <ButtonDc
                  short
                  text="Previous"
                  action={handlePreviousSlide}
                  type="background"
                />
                <ButtonDc
                  short
                  text={sliderIndex === 5 ? "Done" : "Next"}
                  action={handleNextSlide}
                  type="primary"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
