import React, { useRef, useState, ChangeEvent, useEffect } from "react";
import ReactCrop, {
  PercentCrop,
  makeAspectCrop,
  centerCrop,
  convertToPixelCrop,
} from "react-image-crop";
import Webcam from "react-webcam";
import setCanvasPreview from "setCanvasPreview";
import "react-image-crop/dist/ReactCrop.css";



interface ProfilePicModalProps {
  closeModal: () => void;
  updateAvatar: (dataUrl: string) => void;
  title?: string;
  type?: "PROFILE" | "ID" | "COMMUNITY" | "SELFIE";
}

const ProfilePicModal: React.FC<ProfilePicModalProps> = ({
  closeModal,
  updateAvatar,
  title,
  type,
}) => {
  const imgRef = useRef<HTMLImageElement>(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  const webcamRef = useRef<Webcam>(null);
  const [imgSrc, setImgSrc] = useState<string>("");
  const [crop, setCrop] = useState<PercentCrop>();
  const [aspectRatio, setAspectRatio] = useState<any | undefined>();
  const [error, setError] = useState<string>("");
  const MIN_DIMENSION = (type === "SELFIE" || type === "PROFILE") ? 100 : 300;

  useEffect(() => {
    switch (type) {
      case "SELFIE":
        setAspectRatio(1);
        break;
      case "PROFILE":
        setAspectRatio(1);
        break;
      case "ID":
      case "COMMUNITY":
        setAspectRatio(1.5);
        break;
      default:
        setAspectRatio(undefined);
    }
  }, [type]);

  const onSelectFile = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const imageElement = new Image();
      const imageUrl = reader.result?.toString() || "";
      imageElement.src = imageUrl;

      imageElement.addEventListener("load", (e) => {
        if (error) setError("");
        const target = e.target as HTMLImageElement;
        const { naturalWidth, naturalHeight } = target;
        if (naturalWidth < MIN_DIMENSION || naturalHeight < MIN_DIMENSION) {
          setError("Image must be at least 300 x 300 pixels.");
          return setImgSrc("");
        }
      });
      setImgSrc(imageUrl);
    });
    reader.readAsDataURL(file);
  };

  const capture = () => {
    const imageSrc = webcamRef.current?.getScreenshot();
    if (imageSrc) {
      setImgSrc(imageSrc);
    }
  };

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement, Event>) => {
    const { width, height } = e.currentTarget;
    const cropWidthInPercent = (MIN_DIMENSION / width) * 100;

    const crop: PercentCrop = makeAspectCrop(
      {
        unit: "%",
        width: cropWidthInPercent,
      },
      aspectRatio ?? 1,
      width,
      height
    );
    const centeredCrop: PercentCrop = centerCrop(crop, width, height);
    setCrop(centeredCrop);
  };

  const cropImage = () => {
    if (!imgRef.current || !previewCanvasRef.current || !crop) return;

    setCanvasPreview(
      imgRef.current,
      previewCanvasRef.current,
      convertToPixelCrop(crop, imgRef.current.width, imgRef.current.height)
    );
    const dataUrl: string | undefined = previewCanvasRef.current.toDataURL();
    if (dataUrl) {
      updateAvatar(dataUrl);
    }
    closeModal();
  };

  return (
    <>
      <span className="flex mb-5 text-white">
        {title ? title : "Choose profile photo"}
      </span>

      {type !== "SELFIE" && <label className="block text-white mb-3 w-fit">
        <input
          type="file"
          accept="image/*"
          onChange={onSelectFile}
          className="block w-full text-m file:mr-4 file:py-1 file:px-2 file:rounded-full file:border-0 file:text-xs file:bg-primary file:text-black hover:file:bg-primaryActive"
        />
      </label>}
      <div className="flex flex-col gap-1 mb-5">
        <span className="text-darkGray">Supported file types: PNG, JPG, JPEG, SVG, WebP</span>
        <span className="text-darkGray">Maximum file size: 5 MB</span>
      </div>
      {error && <p className="text-brightRed text-xs">{error}</p>}
      {!imgSrc && (type === "PROFILE" || type === "SELFIE" || type === "ID") && (
        <div className="flex flex-col items-center">
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            className="rounded-lg"
          />
          <button
            className="text-black text-xs py-2 px-4rounded-2xl mt-4 bg-primaryActive hover:bg-primary"
            onClick={capture}
          >
            Capture Photo
          </button>
          {type === "SELFIE" && <div className="text-white w-full justify-center text-center mt-10">
            <span>NOTE: If a webcam is not available on your PC, please proceed by using the Duel Cube mobile app or go back and scan the QR code using your mobile.</span>
          </div>}
        </div>
      )}
      {imgSrc && (
        <div className="flex flex-col items-center ">
          <ReactCrop
            crop={crop}
            onChange={(pixelCrop, percentCrop) => setCrop(percentCrop)}
            keepSelection
            circularCrop={type === "PROFILE"}
            aspect={aspectRatio}
            minWidth={MIN_DIMENSION}
          >
            <img
              ref={imgRef}
              src={imgSrc}
              alt="Upload"
              style={{ maxHeight: "50vh" }}
              onLoad={onImageLoad}
            />
          </ReactCrop>
          <button
            className="text-black text-xs py-2 px-4 rounded-2xl mt-4 bg-primaryActive hover:bg-primary"
            onClick={cropImage}
          >
            Upload
          </button>
        </div>
      )}
      {crop && (
        <canvas
          ref={previewCanvasRef}
          className="mt-4"
          style={{
            display: "none",
            border: "1px solid black",
            objectFit: "contain",
            width: type === "SELFIE" ? 100 : 150,
            height: type === "SELFIE" ? 100 : 150,
          }}
        />
      )}
    </>
  );
};

export default ProfilePicModal;
