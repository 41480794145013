import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getGames } from "store/matchMaking/actions";
import { Pagination, ThemeProvider, createTheme } from "@mui/material";
import {
  setConsole,
  setMatchFromHome,
  setSelectedGame,
} from "store/matchMaking/matchMakingSlice";
import {
  setSelectedTournamentGame,
  setTournamentConsole,
} from "store/tournament/tournamentSlice";
import ConsolModal from "components/Consoles";
import LoadingAnim from "components/Loader";
import SearchBar from "components/Search";
import CloseIcon from "assets/close-button.svg";
import MatchCard from "components/MatchCard";
import BackIcon from "assets/arrow-right.svg";
import ButtonDc from "components/Button";

const CardModal = () => {
  const [selected, setSelected] = useState<any | null>(null);
  const [visible, setVisible] = useState(false);
  const [results, setResults] = useState<any[]>([]);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const matchMaking = useAppSelector((state) => state.matchMaking);
  const duelpath = useAppSelector(
    (state) => state.matchMaking
  ).selectedDuelPath;
  const games = matchMaking.gamesList;
  const isNextDisabled = !selected;
  const page = matchMaking.page;

  const handleClose = () => {
    navigate("/wagering");
  };
  const onBack = () => {
    if (duelpath === 'DUEL') return navigate("/duels/schedule-match");
    if (duelpath === 'CREATE_MATCHMAKING') return navigate('/duels');
    if (duelpath === 'CREATE_TOURNAMENT') return navigate("/tournament/type");

  };
  const handleClick = (matchItem: any) => {
    setSelected(matchItem);
  };

  const handleNext = () => {
    setVisible(true);
  };
  const handleSearch = (matchItem: any) => {
    handleClick(matchItem);
    setVisible(true);
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    dispatch(getGames(value));
  };

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  useEffect(() => {
    dispatch(getGames(1));
  }, [dispatch]);

  useEffect(() => {
    if (duelpath === "CREATE_TOURNAMENT") {
      dispatch(setSelectedTournamentGame(selected));
    } else {
      dispatch(setSelectedGame(selected));
    }
  }, [dispatch, selected]);

  return (
    <div className="mx-auto w-full  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex   h-screen  p-2">
      <div className="container flex flex-col gap-y-[20px] md:my-16 max-md:pt-5 items-center ">
        <div
          className="  backdrop-blur-sm con rounded-[34px] p-10
        overflow-y-auto w-[calc(100%-176px)] min-h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)] justify-between flex flex-col  max-md:h-[95vh]"
        >
          <div className="w-full mb-5 flex justify-between">
            {duelpath === "CREATE_TOURNAMENT" ? <span className="text-lg text-subText items-center font-normal uppercase">
              {duelpath}
            </span>
              :
              <span className="text-lg text-subText items-center font-normal uppercase">
                {duelpath} {'>'} {matchMaking?.matchSchedule}
              </span>
            }

          </div>
          <div className="w-full rounded-t-[34px] flex items-center justify-between">
            <div className="flex flex-row items-center gap-5">
              <button className="flex rotate-180 w-[25px] h-[25px]" onClick={onBack}>
                <img src={BackIcon} alt="Close" />
              </button>
              <span className="text-3xl text-white items-center font-normal">
                Select a game
              </span>
            </div>
            <button className=" w-[25px] h-[25px]" onClick={handleClose}>
              <img src={CloseIcon} alt="Close" />
            </button>
          </div>
          <div className="text-white mt-4">
            <SearchBar
              searchIcon
              placeholder="Search games"
              type="games"
              setResults={(v) => setResults(v)}
              onClick={handleSearch}
            />
          </div>
          <div className="w-full md:h-[calc(90vh-430px)] mt-5 flex gap-x-[40px] gap-y-[20px] flex-wrap overflow-y-auto justify-center p-1 max-md:h-2/3">
            {games.map((item, index) => (
              <MatchCard
                key={index}
                id={`${index}`}
                thumbnail={item.image}
                item={item}
                name={item.name}
                selected={selected?._id === item._id}
                onClick={handleClick}
              />
            ))}
          </div>
          <div className="flex absolute justify-center bottom-28 left-0 right-0">
            <ThemeProvider theme={darkTheme}>
              <Pagination
                count={page}
                variant="outlined"
                shape="rounded"
                color="standard"
                onChange={handlePageChange}
              />
            </ThemeProvider>
          </div>
          <ButtonDc
            type="primary"
            className="absolute bottom-10 max-md:w-full "
            textClassName="max-md:text-sm"
            text={'Next'}
            action={handleNext}
            disabled={isNextDisabled}
          />
        </div>
      </div>
      <ConsolModal
        visible={visible}
        onPress={(item: any) => {
          if (duelpath === "CREATE_TOURNAMENT") {
            dispatch(setTournamentConsole(item));
          } else {
            dispatch(setConsole(item));
          }
          dispatch(setMatchFromHome(false));
          setVisible(false);
          navigate("/duels/select-platform");
        }}
        data={selected?.consoles}
        onClose={() => setVisible(false)}
      />
      <LoadingAnim loading={matchMaking.loader} />
    </div>
  );
};

export default CardModal;
