import React, { useEffect, useState } from "react";
import CloseIcon from "assets/close-button.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AppDispatch, RootState } from "store/index";
import ImageHolder from "components/ImagePlaceholder";
import ButtonDc from "components/Button";
import miscsStyles from "styles/components/notification.module.scss";
import {
  followUser,
  getOtherUser,
  getUser,
  unfollowUser,
} from "store/user/action";
import LoadingAnim from "components/Loader";
import FollowModal from "components/FollowModal";

export default function ProfileScreen({ }) {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  const user = useAppSelector((state: RootState) => state.user);
  const { userId, from } = useParams();
  const [data, setData] = useState<any>();
  const [type, setType] = useState<any>();
  const [followersVisible, setFollowersVisible] = React.useState(false);
  const currUser = userId === user.user?._id || userId === undefined;
  const otherUser = user.otherUser;
  let duelHistory = currUser ? user.user?.duelHistory : otherUser?.duelHistory;
  useEffect(() => {
    return setData(currUser ? user.user : otherUser);
  }, [user, otherUser, currUser]);

  const onCancel = () => {
    if (from) return navigate(`/wallet/send-money/${from}`);
    navigate("/");
  };

  useEffect(() => {
    if (duelHistory) duelHistory = [...duelHistory].reverse();
  }, []);

  const renderHistory = (item: any, index: number) => {
    return (
      <div
        key={index}
        className="bg-modalFade p-2 rounded-lg flex flex-col items-center gap-4 w-72"
      >
        <div>
          <span className="text-primary">
            {currUser ? "You" : data?.username}
          </span>
          <span className="text-white"> vs </span>
          <span className="text-primary">{item?.opponent}</span>
        </div>
        <div className="flex flex-row justify-between gap-16">
          <span className="text-white text-sm">{item?.total === 0 ? "Free play" : `Total: $${item?.total}`}</span>
          <span className="text-white text-sm">
            Result: {item?.won ? "You Won" : "You Lost"}
          </span>
        </div>
      </div>
    );
  };

  const onFollow = async () => {
    dispatch(
      followUser({
        payload: { user: userId },
        callback: () => {
          dispatch(getOtherUser(userId)).then((data) => {
            setData(data.payload.userDetails);
          });
        },
      })
    );
  };

  const unFollow = () => {
    dispatch(
      unfollowUser({
        payload: { user: userId },
        callback: () => {
          dispatch(getOtherUser(userId)).then((data) => {
            setData(data.payload.userDetails);
          });
        },
      })
    );
  };

  const onFollowUser = () => {
    !otherUser.alreadyFollowing ? onFollow() : unFollow();
  };

  return (
    <div
      className="mx-auto w-full flex-col bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] 
    bg-right bg-no-repeat flex items-center  h-screen "
    >
      <div className="container flex flex-col gap-y-[20px] md:my-16  max-md:p-3 items-center justify-between ">
        <div
          className={`  backdrop-blur-sm con rounded-[34px] w-[calc(100%-176px)] 
        h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)] max-md:h-[95vh] ${miscsStyles.beautifulScrollbar}`}
        >
          <div className="w-full  flex items-center justify-between absolute top-10 ">
            <button
              className=" w-[25px] h-[25px] absolute right-10"
              onClick={onCancel}
            >
              <img src={CloseIcon} alt="Close" />
            </button>
          </div>
          <div className=" flex flex-col h-full max-md:h-full max-md:w-full">
            <div className="flex md:flex-row max-md:flex-col md:justify-evenly  w-full h-full">
              <div className="flex flex-col items-center gap-y-4 mt-10 max-md:mt-6 p-5">
                <ImageHolder
                  uri={data?.profilePicture}
                  width={100}
                  className="flex w-[150px] h-[150px] rounded-full max-md:w-[15vh] max-md:h-[15vh]"
                />
                <span className="text-white text-[24px]">{data?.username}</span>
                <div className="flex flex-row justify-between items-center gap-x-4 max-md:text-[15px]">
                  <div className="flex flex-col items-center">
                    <span className="text-white ">{data?.duelsWon}</span>
                    <span className="text-white">Duels won</span>
                  </div>
                  <div className="flex flex-col items-center">
                    <span className="text-white ">{data?.followersCount}</span>
                    {currUser ? (
                      <button
                        onClick={() => {
                          setFollowersVisible(true);
                          setType("FOLLOWERS");
                        }}
                      >
                        <span className="text-white hover:text-primary">
                          Followers
                        </span>
                      </button>
                    ) : (
                      <span className="text-white">Followers</span>
                    )}
                  </div>
                  <div className="flex flex-col items-center">
                    <span className="text-white ">{data?.followingCount}</span>
                    {currUser ? (
                      <button
                        onClick={() => {
                          setFollowersVisible(true);
                          setType("FOLLOWING");
                        }}
                      >
                        <span className="text-white hover:text-primary">
                          Following
                        </span>
                      </button>
                    ) : (
                      <span className="text-white">Following</span>
                    )}
                  </div>
                </div>
                <span className="text-subText md:mt-10">{data?.about}</span>
                {currUser ? (
                  <ButtonDc
                    action={() => {
                      navigate("/edit-profile");
                    }}
                    text="Edit Profile"
                    type="primary"
                    short
                  />
                ) : (
                  <ButtonDc
                    action={() => onFollowUser()}
                    text={otherUser.alreadyFollowing ? "Unfollow" : "Follow"}
                    type="primary"
                    short
                  />
                )}
              </div>
              <div className="md:h-full bg-borderColor w-1" />
              <div className="flex flex-col items-center justify-start mt-10 max-md:h-1/2">
                <span className="text-xl text-primaryActive">Duel history</span>
                <div
                  className={`flex flex-col mt-4 h-4/5 gap-y-4 overflow-hidden hover:[overflow:overlay] ${miscsStyles.beautifulScrollbar}`}
                >
                  {duelHistory && duelHistory.map(renderHistory)}
                </div>
                {followersVisible && (
                  <FollowModal
                    type={type}
                    closeModal={() => {
                      setFollowersVisible(false);
                      dispatch(getUser());
                      setData(currUser ? user.user : otherUser);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <LoadingAnim loading={user.loader} />
    </div>
  );
}
