import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AppDispatch, RootState } from "store/index";
import { requestMoney, sendMoney } from "store/wallet/action";
import { WalletStatus } from "types/constants";
import { toast } from "react-toastify";
import CloseIcon from "assets/close-button.svg";
import ImageHolder from "components/ImagePlaceholder";
import ButtonDc from "components/Button";
import LoadingAnim from "components/Loader";

export default function TransactionSummeryScreen({ }) {
  const { from } = useParams();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  const { sendAmount, receiver, balance, loader } = useAppSelector(
    (state) => state.wallet
  );
  const [value, setValue] = useState("");
  const [disabled, setDisabled] = useState(true);

  const onCancel = () => {
    navigate("/wallet/main");
  };
  useEffect(() => {
    setDisabled(false);
  }, []);
  const onValueChange = (e: any) => {
    setValue(e.target.value);
  };

  const onContinue = () => {
    setDisabled(true);
    if (from === WalletStatus.SEND) {
      if (Number(sendAmount) === Number(balance.toFixed(2))) return toast.warning("You cannot send the total amount!");
      if (Number(sendAmount) > balance) return toast.warning("Insufficient Balance!");
      dispatch(
        sendMoney({
          payload: {
            amount: parseFloat(sendAmount ? String(sendAmount) : "0").toFixed(
              2
            ),
            receiverId: receiver?._id,
            note: value,
          },
          callback: () => {
            navigate(`/wallet/transaction-complete/${WalletStatus.SEND}`);
          },
        })
      );
    } else if (from === WalletStatus.REQUEST) {
      dispatch(
        requestMoney({
          payload: {
            amount: parseFloat(sendAmount ? String(sendAmount) : "0").toFixed(
              2
            ),
            userId: receiver?._id,
            note: value,
          },
          callback: () => {
            navigate(`/wallet/transaction-complete/${WalletStatus.REQUEST}`);
          },
        })
      );
    }
  };

  return (
    <div className="mx-auto w-full  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex flex-col  h-screen ">
      <div className="container flex flex-col gap-y-[20px] md:my-16 max-md:p-5 items-center">
        <div
          className="  backdrop-blur-sm con rounded-[34px] p-10
        overflow-y-auto w-[calc(100%-176px)] h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)]  max-md:h-[95vh]"
        >
          <div className="flex flex-col gap-[1em]">
            <div className="w-full rounded-t-[34px] flex items-center justify-between">
              <div className="flex flex-row gap-4 items-center justify-center">
                <span className="text-3xl text-white items-center font-normal">
                  {from === WalletStatus.SEND ? "Send Money" : "Request Money"}
                </span>
              </div>
              <button
                className=" w-[25px] h-[25px] absolute right-10"
                onClick={onCancel}
              >
                <img src={CloseIcon} alt="Close" />
              </button>
            </div>
            <div className="flex flex-row justify-center items-center h-full">
              <div className="flex flex-col items-center justify-center gap-10">
                <div className="flex flex-row gap-8 items-center justify-center mt-8">
                  <div className="flex flex-col justify-center items-center gap-10">
                    <span className="text-white text-3xl max-md:text-lg">
                      {from === WalletStatus.SEND
                        ? " Transaction Summary"
                        : "Request Summary"}
                    </span>
                    <ImageHolder
                      uri={receiver?.profilePicture}
                      className=" w-[10em] h-[10em] rounded-2xl"
                    />
                    <span className="text-placeholder text-xl">
                      {from === WalletStatus.SEND
                        ? "Sending to"
                        : "Request from "}
                      {"\t\t"}
                      <span className="text-primary">{receiver?.username}</span>
                    </span>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center">
                  <span className="text-white text-5xl">
                    {`$${Number(sendAmount).toFixed(2)}`}
                  </span>
                  <textarea
                    className="bg-inputGray rounded-lg h-[5em] w-[20em] border-inputGray text-[18px] text-white m-4 p-4 max-md:w-[15em]"
                    placeholder="Write a message"
                    value={value}
                    onChange={(e) => onValueChange(e)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row justify-center gap-10 mt-16 max-md:flex-col">
            <div>
              <ButtonDc
                text="Back"
                short
                action={() => navigate(`/wallet/confirm-amount/${from}`)}
              />
            </div>
            <div>
              <ButtonDc
                text="Confirm"
                short
                type={"primary"}
                disabled={disabled}
                action={() => onContinue()}
              />
            </div>
          </div>
        </div>
      </div>
      <LoadingAnim loading={loader} />
    </div>
  );
}
