import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AppDispatch, RootState } from "store/index";
import { toast } from "react-toastify";
import { setCommunitiesTitle } from "store/community/communitySlice";
import ProgressLines from "components/ProgressLines";
import ButtonDc from "components/Button";
import CommunityRulesModal from "./rulesModal";
import CloseIcon from "assets/close-button.svg";
import { ReactComponent as Message } from "assets/community/message.svg";
import { ReactComponent as Link } from "assets/community/link.svg";
import { ReactComponent as Image } from "assets/community/image.svg";
import { ReactComponent as Poll } from "assets/community/poll.svg";
import { ReactComponent as Video } from "assets/community/video.svg";
import { ReactComponent as Talk } from "assets/community/talk.svg";

interface PostBoxProps {
  icon: React.ReactNode;
  text: string;
}
const PostBox: React.FC<PostBoxProps> = ({ icon, text }) => (
  <div className="flex flex-col bg-inputGray w-20 h-20 rounded-xl items-center gap-2 justify-center">
    {icon}
    <span className="text-white">{text}</span>
  </div>
);

export default function AddTitleScreen({ }) {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const { communitiesName, communitiesTitle } = useAppSelector(
    (state: RootState) => state.community
  );

  const [communityTitle, setCommunityTitle] = useState(
    communitiesTitle ? communitiesTitle : ""
  );

  const onCancel = () => {
    navigate("/communication/main");
  };

  const onContinue = () => {
    if (communityTitle.trim() === "")
      return toast.warning("Community title cannot be empty.");
    dispatch(setCommunitiesTitle(communityTitle));
    navigate("/communication/add-description");
  };

  const handleTitleChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setCommunityTitle(e.target.value);
  };

  return (
    <div className="mx-auto w-full  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex flex-col  h-screen ">
      <div className="container flex flex-col gap-y-[20px] md:my-16 max-md:p-5 items-center">
        <div
          className="  backdrop-blur-sm con rounded-[34px] p-10
        overflow-y-auto w-[calc(100%-176px)] h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)]  max-md:h-[95vh]"
        >
          <div className="flex flex-col gap-[1em]">
            <div className="w-full rounded-t-[34px] flex items-center justify-between">
              <div className="flex flex-row gap-4 items-center justify-center">
                <span className="text-3xl text-white items-center font-normal max-md:text-xl">
                  The {communitiesName} Community
                </span>
              </div>
              <button
                className=" w-[25px] h-[25px] absolute right-10"
                onClick={onCancel}
              >
                <img src={CloseIcon} alt="Close" />
              </button>
            </div>
          </div>

          <div className="flex flex-col w-full justify-center my-10 items-center gap-4 ">
            <div className="md:h-[500px] flex flex-col w-full items-center gap-4 md:gap-y-8">
              <span className=" text-white text-center md:w-[420px] w-full text-lg md:text-xl">
                Add Title
              </span>
              <div className="flex  flex-col">
                <textarea
                  className=" bg-borderColor align-top gap-5 h-36 w-[70vw] md:w-[490px] rounded-xl p-2 outline-none text-left text-white"
                  placeholder="Add a starting title"
                  value={communityTitle}
                  onChange={handleTitleChange}
                  maxLength={50}
                />
                <span className="flex text-xs justify-end text-white">
                  Max 50
                </span>
              </div>

              <span className=" text-white text-center md:w-[420px] w-full text-sm md:text-lg">
                What are you looking to post?
              </span>
              <div className="flex md:flex-col flex-row gap-8">
                <div className="flex md:flex-row flex-col gap-6">
                  <PostBox text={"Text"} icon={<Message />} />
                  {/* <PostBox text={"Image"} icon={<Image />} /> */}
                  <PostBox text={"Link"} icon={<Link />} />
                </div>
                {/* <div className="flex md:flex-row flex-col gap-6">
                  <PostBox text={"Poll"} icon={<Poll />} />
                  <PostBox text={"Video"} icon={<Video />} />
                  <PostBox text={"Talk"} icon={<Talk />} /> //TODO
                </div> */}
              </div>
              <button
                onClick={() => setModalOpen(true)}
                className=" text-primary text-center text-sm md:text-lg hover:text-primaryActive"
              >
                Rules
              </button>
            </div>

            <div className="px-10 w-full flex flex-col max-md:absolute bottom-10 justify-center max-md:flex-col">
              <ProgressLines count={4} selected={2} />
              <div className="flex flex-row justify-center gap-10 mt-5 max-md:flex-col">
                <div>
                  <ButtonDc
                    text="Next"
                    short
                    type={"primary"}
                    action={() => onContinue()}
                    disabled={!communityTitle}
                  />
                </div>
              </div>
            </div>
          </div>
          {modalOpen && (
            <CommunityRulesModal
              visible={modalOpen}
              onClose={() => setModalOpen(false)}
            />
          )}
        </div>
      </div>
    </div>
  );
}
