import React, { memo } from "react";
import styles from "styles/components/dropDown.module.scss";

interface DropDownProps {
  options: any[];
  defaultValue?: {
    text: string;
    value: string;
  };
  placeholder?: string;
  action: (data: any) => void;
  icon?: string;
  disabled?: boolean;
}

const DropdownSelect: React.FC<DropDownProps> = ({
  options,
  defaultValue,
  placeholder,
  action,
  icon,
  disabled,
}) => {
  return (
    <div className={styles.shortInputContainer}>
      {placeholder && (
        <label className="text-number">{placeholder + " :"}</label>
      )}
      <div className={styles.shortInputWrapper}>
        {icon && <img src={icon} alt="" />}
        <select
          onChange={action}
          defaultValue={defaultValue?.value}
          disabled={disabled}
          style={{ border: "none" }}
          className="bg-placeholder w-full "
        >
          {options.map((option, index) => (
            <option key={index} value={option.value}>
              {option.text}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default DropdownSelect;
