import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AppDispatch, RootState } from "store/index";
import { games } from "../../../types/games";
import { getLeaderboard } from "store/user/action"; //TODO

import { ReactComponent as Arrow } from "assets/arrow-right.svg";
import { ReactComponent as GameRecordIcon } from "assets/games/GameRecordIcon.svg";
import CloseIcon from "assets/close-button.svg";

import miscsStyles from "styles/miscs/miscs.module.scss";
import LoadingAnim from "components/Loader";
import { getMinigamesLeaderBoard } from "store/gameHub/action";

interface GameTileProps {
  title: string;
  image: string;
  onPress: () => void;
}

const GameTile: React.FC<GameTileProps> = ({ title, image, onPress }) => {
  return (
    <button
      className={
        "flex justify-between bg-borderColor items-center p-2 px-5 rounded-xl text-white transform transition-transform hover:scale-105 hover:shadow-lg"
      }
      onClick={onPress}
    >
      <div className="flex items-center md:gap-3 col-span-1">

        <div className="flex max-md:w-[60vw] items-center gap-3 col-span-2 ">
          <div className="rounded-full w-[50px] h-[50px] ">
            <img src={image} alt={title} className="rounded-full" />
          </div>
          <span className="text-lg max-md:text-sm text-left">{title}</span>
        </div>
      </div>
      <div className="col-span-1 items-end text-right"><Arrow /></div>
    </button>
  );
};


const GameRecordsScreen: React.FC = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  const user = useAppSelector((state: RootState) => state.user);

  // useEffect(() => {
  //   dispatch(getMinigamesLeaderBoard({
  //     payload: { userId: user._id }, callback(data) {

  //     },
  //   }));
  // }, [dispatch]);

  const handleGameSelect = (gameData: any) => {
    navigate(`/gaming/game-records/record/${gameData.title}`, { state: { gameData } });
  };

  const handleClose = () => {
    navigate("/gaming/games");
  };

  return (
    <div className="mx-auto w-full bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex h-screen">
      <div className="container flex items-center flex-col gap-y-[20px] md:my-16 max-md:p-3">
        <div className="backdrop-blur-sm con rounded-[34px] p-10 max-md:p-5 w-[calc(100%-176px)] h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)] max-md:h-[95vh]">
          <div className="w-full rounded-t-[34px] flex items-center justify-between">
            <span className="text-3xl text-white font-normal">Game Records</span>
            <button className="w-[25px] h-[25px]" onClick={handleClose}>
              <img src={CloseIcon} alt="Close" />
            </button>
          </div>
          <div className="max-md:mt-5 md:grid md:grid-cols-2 items-center ">
            <div className="flex flex-col items-center justify-between p-4 gap-4">
              <GameRecordIcon className="md:w-40 md:h-40" />
              <span className="text-white text-center w-[300px]">View the top scores for each game. Select a game to see its highest scores and top players. Aim to set new records and become the best player!</span>
            </div>
            <div
              className={`md:mt-10 flex flex-col md:h-[calc(100vh-300px)] h-[calc(100vh-400px)] gap-3 overflow-y-auto ${miscsStyles.beautifulScrollbarHidden}`}
            >
              {games.map((game: any) => (
                <GameTile
                  key={game.id}
                  title={game.title}
                  image={game.image}
                  onPress={() => handleGameSelect(game)}
                />
              ))}
            </div>
          </div>
        </div>
        <LoadingAnim loading={user.loader} />
      </div>
    </div>
  );
};

export default GameRecordsScreen;
