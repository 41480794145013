import { createSlice } from "@reduxjs/toolkit";
import {
  getStakeAmount,
  getStakeAvailableChallenges,
  getStakeAvailableDuels,
  getStakeAvailableTournaments,
  getStakedChallenges,
  getStakedMatches,
  getStakedTournaments,
} from "./action";
import { signOut } from "store/auth/actions";

enum stakeMethods {
  TOURNAMENTS = "TOURNAMENTS",
  DUELS = "DUELS",
  CHALLENGES = "CHALLENGES",
  NONE = "NONE",
}

interface StakingSlice {
  matchId: string;
  from: string;
  team: string;
  player: string;
  amount: number;
  loading: boolean;
  err: boolean;
  stakeType: boolean;
  stakeAmount: { team1StakeAmount: number; team2StakeAmount: number };
  tournaments: {
    stakingTournaments: any[];
    stakingTournamentsCurrentPage: number;
    stakingTournamentsTotalPages: number;
    stakedTournaments: any[];
    stakedTournamentsCurrentPage: number;
    stakedTournamentsTotalPages: number;
  };
  duels: {
    stakingDuels: any[];
    stakingDuelsCurrentPage: number;
    stakingDuelsTotalPages: number;
    stakedDuels: any[];
    stakedDuelsCurrentPage: number;
    stakedDuelsTotalPages: number;
  };
  challenges: {
    stakingChallenges: any[];
    stakingChallengesCurrentPage: number;
    stakingChallengesTotalPages: number;
    stakedChallenges: any[];
    stakedChallengesCurrentPage: number;
    stakedChallengesTotalPages: number;
  };
  success: boolean;
  stakingMethod: stakeMethods;
}

const initialState: StakingSlice = {
  matchId: "",
  from: "",
  team: "",
  player:"",
  amount: 0,
  loading: false,
  err: false,
  stakeType: true,
  stakeAmount: { team1StakeAmount: 0, team2StakeAmount: 0 },
  tournaments: {
    stakingTournaments: [],
    stakingTournamentsCurrentPage: 0,
    stakingTournamentsTotalPages: 1,
    stakedTournaments: [],
    stakedTournamentsCurrentPage: 0,
    stakedTournamentsTotalPages: 1,
  },
  duels: {
    stakedDuels: [],
    stakedDuelsCurrentPage: 0,
    stakedDuelsTotalPages: 1,
    stakingDuels: [],
    stakingDuelsCurrentPage: 0,
    stakingDuelsTotalPages: 1,
  },
  challenges: {
    stakedChallenges: [],
    stakedChallengesCurrentPage: 0,
    stakedChallengesTotalPages: 1,
    stakingChallenges: [],
    stakingChallengesCurrentPage: 0,
    stakingChallengesTotalPages: 1,
  },
  success: false,
  stakingMethod: stakeMethods.NONE,
};

export const stakeSlice = createSlice({
  name: "stake",
  initialState,
  reducers: {
    setStakeMatch: (state, action) => {
      state.matchId = action?.payload;
    },
    setStakeTeam: (state, action) => {
      state.team = action?.payload;
    },
    setStakeAmount: (state, action) => {
      state.amount = action?.payload;
    },
    setStakeType: (state, action) => {
      state.stakeType = action?.payload;
    },
    setStakeFrom: (state, action) => {
      state.from = action?.payload;
    },
    setStakeMethod: (state, action) => {
      state.stakingMethod = action?.payload;
    },
    setStakePlayer: (state, action) => {
      state.player = action?.payload;
    },
    resetStakingSlice: (state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStakeAmount.fulfilled, (state, action) => {
      state.stakeAmount = action?.payload;
    });
    builder.addCase(getStakeAvailableDuels.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getStakeAvailableDuels.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.page === 1) {
        state.duels.stakingDuels = action.payload.stakeMatches;
        state.duels.stakingDuelsCurrentPage = action.payload.page;
        state.duels.stakingDuelsTotalPages = action.payload.totalPages;
      } else if (state.duels.stakingDuelsCurrentPage < action.payload.page) {
        state.duels.stakingDuels = action.payload.stakeMatches;
        state.duels.stakingDuelsCurrentPage = action.payload.page;
        state.duels.stakingDuelsTotalPages = action.payload.totalPages;
      }
    });
    builder.addCase(getStakeAvailableDuels.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getStakedMatches.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getStakedMatches.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.page === 1) {
        state.duels.stakedDuels = action.payload.stakedMatches;
        state.duels.stakedDuelsCurrentPage = action.payload.page;
        state.duels.stakedDuelsTotalPages = action.payload.totalPages;
      } else if (state.duels.stakedDuelsCurrentPage < action.payload.page) {
        state.duels.stakedDuels = action.payload.stakedMatches;
        state.duels.stakedDuelsCurrentPage = action.payload.page;
        state.duels.stakedDuelsTotalPages = action.payload.totalPages;
      }
    });
    builder.addCase(getStakedMatches.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getStakeAvailableChallenges.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getStakeAvailableChallenges.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.page === 1) {
        state.challenges.stakingChallenges = action.payload.stakeableChallenges;
        state.challenges.stakingChallengesCurrentPage = action.payload.page;
        state.challenges.stakingChallengesTotalPages =
          action.payload.totalPages;
      } else if (
        state.challenges.stakingChallengesCurrentPage < action.payload.page
      ) {
        state.challenges.stakingChallenges = action.payload.stakeableChallenges;
        state.challenges.stakingChallengesCurrentPage = action.payload.page;
        state.challenges.stakingChallengesTotalPages =
          action.payload.totalPages;
      }
    });
    builder.addCase(getStakeAvailableChallenges.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getStakedChallenges.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getStakedChallenges.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.page === 1) {
        state.challenges.stakedChallenges = action.payload.stakedChallenges;
        state.challenges.stakedChallengesCurrentPage = action.payload.page;
        state.challenges.stakedChallengesTotalPages = action.payload.totalPages;
      } else if (
        state.challenges.stakedChallengesCurrentPage < action.payload.page
      ) {
        state.challenges.stakedChallenges = action.payload.stakedChallenges;
        state.challenges.stakedChallengesCurrentPage = action.payload.page;
        state.challenges.stakedChallengesTotalPages = action.payload.totalPages;
      }
    });
    builder.addCase(getStakedChallenges.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getStakeAvailableTournaments.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getStakeAvailableTournaments.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.page === 1) {
        state.tournaments.stakingTournaments = action.payload.stakeTournaments;
        state.tournaments.stakingTournamentsCurrentPage = action.payload.page;
        state.tournaments.stakingTournamentsTotalPages =
          action.payload.totalPages;
      } else if (
        state.tournaments.stakingTournamentsCurrentPage < action.payload.page
      ) {
        state.tournaments.stakingTournaments = action.payload.stakeTournaments;
        state.tournaments.stakingTournamentsCurrentPage = action.payload.page;
        state.tournaments.stakingTournamentsTotalPages =
          action.payload.totalPages;
      }
    });
    builder.addCase(getStakeAvailableTournaments.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getStakedTournaments.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getStakedTournaments.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.page === 1) {
        state.tournaments.stakedTournaments = action.payload.stakedTournaments;
        state.tournaments.stakedTournamentsCurrentPage = action.payload.page;
        state.tournaments.stakedTournamentsTotalPages =
          action.payload.totalPages;
      } else if (
        state.tournaments.stakedTournamentsCurrentPage < action.payload.page
      ) {
        state.tournaments.stakedTournaments = action.payload.stakedTournaments;
        state.tournaments.stakedTournamentsCurrentPage = action.payload.page;
        state.tournaments.stakedTournamentsTotalPages =
          action.payload.totalPages;
      }
    });
    builder.addCase(getStakedTournaments.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(signOut.fulfilled, (state) => {
      return initialState;
    });
  },
});

export default stakeSlice.reducer;
export const {
  setStakeAmount,
  setStakeTeam,
  setStakeMatch,
  setStakeType,
  setStakeFrom,
  setStakeMethod,
  resetStakingSlice,
  setStakePlayer,
} = stakeSlice.actions;
