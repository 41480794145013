import React, { useState } from "react";
import CloseIcon from "assets/close-button.svg";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { AppDispatch, RootState } from "store/index";
import DropdownSelect from "components/DropdownSelect";
import ButtonDc from "components/Button";
import TextInput from "components/TextInput";
import ProgressLines from "components/ProgressLines";
import CustomSwitch from "components/Switch";
import {
  setAdult,
  setCommunitiesName,
  setCommunitiesType,
} from "store/community/communitySlice";
import { paragraphRegex } from "constant/validations";
import { toast } from "react-toastify";

enum privacyTypes {
  PUBLIC = "Public",
  PRIVATE = "Private",
}

export default function CreateCommunityScreen({ }) {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useAppDispatch();
  const { adult, communitiesName, communityState } = useAppSelector(
    (state: RootState) => state.community
  );

  const [communityName, setCommunityName] = useState(
    communitiesName ? communitiesName : ""
  );
  const [isSwitchOn, setIsSwitchOn] = useState(adult ? adult : false);
  const [type, setType] = useState(privacyTypes.PUBLIC);

  const options = [
    { text: "Public", value: privacyTypes.PUBLIC },
    { text: "Private", value: privacyTypes.PRIVATE },
  ];

  const onCancel = () => {
    navigate("/communication/main");
  };

  const onContinue = () => {
    if (!paragraphRegex.test(communityName)) {
      toast.warning("Invalid Community name");
      return;
    }
    dispatch(setCommunitiesName(communityName));
    dispatch(setCommunitiesType(type));
    dispatch(setAdult(isSwitchOn));
    navigate("/communication/add-title");
  };

  const handleNameChange = (e: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setCommunityName(e.target.value);
  };

  const handleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsSwitchOn(event.target.checked);
  };
  return (
    <div className="mx-auto w-full  bg-cardBackground bg-[url('/src/assets/ineternal-page-backdrop.svg')] bg-right bg-no-repeat flex flex-col  h-screen ">
      <div className="container flex flex-col gap-y-[20px] md:my-16 max-md:p-5 items-center">
        <div
          className="  backdrop-blur-sm con rounded-[34px] p-10
        overflow-y-auto w-[calc(100%-176px)] h-[calc(100vh-160px)] max-md:w-[calc(100%-10px)]  max-md:h-[95vh]"
        >
          <div className="flex flex-col gap-[1em]">
            <div className="w-full rounded-t-[34px] flex items-center justify-between">
              <div className="flex flex-row gap-4 items-center justify-center">
                <span className="text-3xl text-white items-center font-normal max-md:text-xl">
                  {communityState === "EDIT"
                    ? "Edit Community"
                    : "Create Community"}
                </span>
              </div>
              <button
                className=" w-[25px] h-[25px] absolute right-10"
                onClick={onCancel}
              >
                <img src={CloseIcon} alt="Close" />
              </button>
            </div>
          </div>
          <div className="flex flex-col w-full justify-center my-10 items-center gap-4">
            <div className="md:h-[500px] flex flex-col w-full items-center gap-y-8">
              <div className="flex  flex-col">
                <TextInput
                  label="Community Name"
                  onChange={handleNameChange}
                  maxLength={25}
                  value={communityName}
                />
                <span className="flex text-xs justify-end text-white">
                  Max 25
                </span>
              </div>
              <div className="flex md:w-[490px] w-full flex-col bg-borderColor p-3 rounded-xl">
                <DropdownSelect
                  placeholder="Community Type"
                  options={options}
                  action={(e) => setType(e.target.value)}
                />
              </div>
              <span className=" text-white text-left md:w-[420px] w-full text-sm md:text-lg">
                This setting will allow anyone to view, post, & comment
              </span>
              <div className="flex items-center justify-between md:w-[420px] w-full">
                <span className=" text-white text-sm md:text-lg">18 & Up</span>
                <CustomSwitch
                  checked={isSwitchOn}
                  onChange={handleSwitchChange}
                />
              </div>
            </div>
            <div className="px-10 w-full flex flex-col max-md:absolute bottom-10 max-md:flex-col">
              <ProgressLines count={4} selected={1} />
              <div className="flex flex-row justify-center gap-10 mt-5 max-md:flex-col">
                <div>
                  <ButtonDc
                    text={
                      communityState === "EDIT"
                        ? "Edit Community"
                        : "Start Community"
                    }
                    short
                    type={"primary"}
                    action={() => onContinue()}
                    disabled={!communityName}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
