import LoadingAnim from "components/Loader";
import { getTokenFromFirebase } from "firebaseConfig/firebase";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setOtpConfirm } from "store/auth/authSlice";
import { ReactComponent as GoogleLogo } from "../../assets/Google_Icon.svg";
import { ReactComponent as FacebookLogo } from "../../assets/Facebook.svg";
import { ReactComponent as AppleLogo } from "../../assets/apple_logo.svg";

import {
  atLeastOneLowercase,
  atLeastOneNumber,
  atLeastOneUppercase,
  emailRegex,
  min8chars,
  passwordRegex,
  usernameRegex,
} from "../../constant/validations";
import { signUp } from "./../../store/auth/actions";
import { AppDispatch } from "./../../store/index";
import { toast } from "react-toastify";

export default function SignUpScreen({ setLoggedIn }: any) {
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [referral, setReferral] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setErrors] = useState({ username: "", email: "", password: "", confirmPassword: "" });

  const emailRef = useRef<HTMLInputElement>(null);
  const referralRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const confirmPasswordRef = useRef<HTMLInputElement>(null);
  const submitRef = useRef<HTMLButtonElement>(null);

  const [deviceToken, setDeviceToken] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [isPasswordFocused, setIsPasswordFocused] = useState(false);

  // console.log("deviceToken", deviceToken);
  const dispatch: AppDispatch = useDispatch();
  const user = useSelector((state: any) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (user.userToken) return navigate("/");
  }, [user]);

  useEffect(() => {
    (async () => {
      const token = (await getTokenFromFirebase()) as string;
      setDeviceToken(token);
    })();
  }, []);

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (emailRef.current && (!email || errors.email)) {
        emailRef.current.focus();
      } else if (referralRef.current && email && !password && !confirmPassword && !referral) {
        referralRef.current.focus();
      } else if (passwordRef.current && (!password || errors.password)) {
        passwordRef.current.focus();
      } else if (confirmPasswordRef.current && (!confirmPassword || errors.confirmPassword)) {
        confirmPasswordRef.current.focus();
      } else if (submitRef.current) {
        submitRef.current.focus();
      }
    }
  };

  const validateInputs = () => {
    const usernameError = validateUsername(userName);
    const emailError = validateEmail(email);
    const passwordError = validatePassword(password);
    const confirmPasswordError = validateConfirmPassword(confirmPassword);
    setErrors({ username: usernameError, password: passwordError, email: emailError, confirmPassword: confirmPasswordError });
    return !usernameError && !passwordError && !emailError && !confirmPasswordError;
  };
  const validateUsername = (value: string): string => {
    if (!usernameRegex.test(value)) {
      return "Invalid username format.";
    }
    return "";
  };

  const validateEmail = (value: string): string => {
    if (!emailRegex.test(value)) {
      return "Invalid email format.";
    }
    return "";
  };

  const validatePassword = (value: string): string => {
    if (!passwordRegex.test(value)) {
      return "Password must be at least 8 characters, include a letter and a number.";
    }
    return "";
  };
  const validateConfirmPassword = (value: string): string => {
    if (!passwordRegex.test(value) || value !== password) {
      return "Please check your password and confirm password fields.";
    }
    return "";
  };

  const handleUsernameChange = (value: string) => {
    setUserName(value);
    const error = validateUsername(value);
    setErrors((prevErrors) => ({ ...prevErrors, username: error }));
  };

  const handleEmailChange = (value: string) => {
    setEmail(value);
    const error = validateEmail(value);
    setErrors((prevErrors) => ({ ...prevErrors, email: error }));
  };

  const handlePasswordChange = (value: string) => {
    setPassword(value);
    const error = validatePassword(value);
    setErrors((prevErrors) => ({ ...prevErrors, password: error }));
  };

  const handleConfirmPasswordChange = (value: string) => {
    setConfirmPassword(value);
    const error = validateConfirmPassword(value);
    setErrors((prevErrors) => ({ ...prevErrors, confirmPassword: error }));
  };
  const render = async () => {
    if (!validateInputs()) {
      return;
    }
    if (
      !atLeastOneLowercase.test(password) ||
      !atLeastOneUppercase.test(password) ||
      !atLeastOneNumber.test(password) ||
      !min8chars.test(password) ||
      password !== confirmPassword
    ) {
      toast.warning("Please check your password and confirm password fields.");
      return;
    }
    dispatch(
      signUp({
        username: userName,
        email: email,
        referCode: referral,
        password: password,
        deviceToken: deviceToken,
      })
    ).then((data) => {
      if (data?.type === "auth/signUp/rejected") return;

      dispatch(setOtpConfirm({ email: email, username: userName }));
      navigate("/verify-otp/verify");
    });
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const Validate = ({ text, regex }: { text: string; regex: RegExp }) => {
    return (
      <div className="flex w-[9em] md:w-[17em] items-center">
        {regex.test(password) ? (
          <i
            className="fas fa-check-circle text-lightGreen"
            style={{ fontSize: "0.62em" }}
            aria-hidden="true"
          ></i>
        ) : (
          <i
            className="fas fa-sync text-[#d0d0d0]"
            style={{ fontSize: "0.62em" }}
            aria-hidden="true"
          ></i>
        )}
        <span className="text-[#d0d0d0] text-center ml-2 text-[12px]">
          {text}
        </span>
      </div>
    );
  };

  const handleSignUpWithFacebook = async () => {
    window.open(`${process.env.REACT_APP_BASE_URL}/auth/facebook?deviceToken=${deviceToken}`, "_self");
  };

  const handleSignUpWithGoogle = async () => {
    window.open(`${process.env.REACT_APP_BASE_URL}/auth/google?deviceToken=${deviceToken}`, "_self");
  };

  const handleSignUpWithApple = () => {
    window.open(`${process.env.REACT_APP_BASE_URL}/auth/apple?deviceToken=${deviceToken}`, "_self");
  };

  return (
    <div className="bg-background min-h-screen h-auto bg-[url('/src/assets/Backdrop-log.png')] bg-no-repeat bg-fit flex items-center justify-center tab:justify-end">
      <div className="h-auto w-auto  border-[1px] rounded-3xl border-primaryYellow tab:mr-28 ">
        <div className="flex flex-col items-center justify-center w-full gap-[15px] p-10">
          <h1 className="text-[32px] font-bold text-primaryYellow">Sign Up</h1>
          <div className="flex flex-col items-center max-w-[505px] min-w-[280px] w-[35vw] gap-5">
            <button
              onClick={handleSignUpWithFacebook}
              // disabled={process.env.REACT_APP_ENV === "PRODUCTION"}
              className="items-center text-white justify-center text-center flex flex-row gap-5 bg-blueBg w-full h-[51.6px] mx-6 mt-5 rounded-xl"
            >
              <FacebookLogo width={20} height={20} />
              Sign up with Facebook
            </button>
          </div>
          <div className="flex flex-col items-center max-w-[505px] min-w-[280px] w-[35vw] gap-5">
            <button
              onClick={handleSignUpWithGoogle}
              className="items-center text-black justify-center font-semibold text-center flex flex-row gap-5 bg-white w-full h-[51.6px] mx-6 rounded-xl"
            >
              <GoogleLogo width={24} height={24} />
              Sign up with Google
            </button>
          </div>
          <div className="flex flex-col items-center max-w-[505px] min-w-[280px] w-[35vw] gap-5">
            <button
              onClick={handleSignUpWithApple}
              className="items-center text-black justify-center font-semibold text-center flex flex-row gap-5 bg-white w-full h-[51.6px] mx-6 rounded-xl"
            >
              <AppleLogo width={24} height={24} />
              Sign up with Apple
            </button>
          </div>
          <div className="flex flex-row items-center justify-center gap-5 max-w-[505px] min-w-[280px] w-[35vw] mt-5">
            <div className="w-[45%] h-[1px] bg-[#d0d0d0]"></div>
            <p className="text-[#d0d0d0] text-[12px] text-center">Or</p>
            <div className="w-[45%] h-[1px] bg-[#d0d0d0]"></div>
          </div>
          <div className="flex flex-col max-w-[505px] min-w-[280px] w-[35vw] items-center">
            <div className="relative w-full">
              <input
                type="text"
                value={userName}
                onChange={(e) => handleUsernameChange(e.target.value)}
                onKeyDown={handleKeyPress}
                id="floating_filled"
                className={`block rounded-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-white outline-none bg-inputGray border-0 border-b-2 ${errors.username ? "border-red-600 focus:border-red-600" : "border-inputGray focus:border-primaryYellow"} appearance-none focus:outline-none focus:ring-0  peer`}
                placeholder=" "
              />
              <label
                htmlFor="floating_filled"
                className="absolute text-sm text-white duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-primaryYellow peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
              >
                Username *
              </label>
            </div>
            <div className="relative w-full mt-4">
              <input
                ref={emailRef}
                type="email"
                value={email}
                onChange={(e) => handleEmailChange(e.target.value)}
                onKeyDown={handleKeyPress}
                id="floating_filled"
                className={`block rounded-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-white outline-none bg-inputGray border-0 border-b-2 ${errors.email ? "border-red-600 focus:border-red-600" : "border-inputGray focus:border-primaryYellow"} appearance-none focus:outline-none focus:ring-0  peer`}
                placeholder=" "
              />
              <label
                htmlFor="floating_filled"
                className="absolute text-sm text-white duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-primaryYellow peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
              >
                Email *
              </label>
            </div>
            <div className="relative w-full mt-4">
              <input
                ref={referralRef}
                type="referral"
                value={referral}
                onChange={(e) => setReferral(e.target.value)}
                onKeyDown={handleKeyPress}
                id="floating_filled"
                className="block rounded-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-white outline-none bg-inputGray border-0 border-b-2 border-inputGray appearance-none focus:outline-none focus:ring-0 focus:border-primaryYellow peer"
                placeholder=" "
              />
              <label
                htmlFor="floating_filled"
                className="absolute text-sm text-white duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-primaryYellow peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
              >
                Referral Code
              </label>
            </div>
            <div className="flex flex-col tab:flex-row w-full gap-4 mt-4">
              <div className="relative w-full">
                <input
                  ref={passwordRef}
                  type={showPassword ? "text" : "password"}
                  value={password}
                  onChange={(e) => handlePasswordChange(e.target.value)}
                  onFocus={() => setIsPasswordFocused(true)}
                  onBlur={() => setIsPasswordFocused(false)}
                  onKeyDown={handleKeyPress}
                  id="password"
                  className={`block rounded-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-white outline-none bg-inputGray border-0 border-b-2 ${errors.password ? "border-red-600 focus:border-red-600" : "border-inputGray focus:border-primaryYellow"} appearance-none focus:outline-none focus:ring-0  peer`}

                  placeholder=" "
                />
                <label
                  htmlFor="password"
                  className="absolute text-sm text-white duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-primaryYellow peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
                >
                  Password *
                </label>
                <button
                  onClick={togglePasswordVisibility}
                  type="button"
                  className="absolute right-2 top-4 text-white"
                >
                  <i
                    className={`fas ${showPassword ? "fa-eye-slash" : "fa-eye"
                      }`}
                  ></i>
                </button>
              </div>
              <div className="relative w-full">
                <input
                  ref={confirmPasswordRef}
                  type={showPassword ? "text" : "password"}
                  value={confirmPassword}
                  onChange={(e) => handleConfirmPasswordChange(e.target.value)}
                  onFocus={() => setIsPasswordFocused(true)}
                  onBlur={() => setIsPasswordFocused(false)}
                  onKeyDown={handleKeyPress}
                  id="confirmPassword"
                  className={`block rounded-lg px-2.5 pb-2.5 pt-5 w-full text-sm text-white outline-none bg-inputGray border-0 border-b-2 ${errors.confirmPassword ? "border-red-600 focus:border-red-600" : "border-inputGray focus:border-primaryYellow"} appearance-none focus:outline-none focus:ring-0  peer`}

                  placeholder=" "
                />
                <label
                  htmlFor="confirmPassword"
                  className="absolute text-sm text-white duration-300 transform -translate-y-4 scale-75 top-4 z-10 origin-[0] start-2.5 peer-focus:text-primaryYellow peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
                >
                  Confirm Password *
                </label>
                <button
                  onClick={togglePasswordVisibility}
                  type="button"
                  className="absolute right-2 top-4 text-white"
                >
                  <i
                    className={`fas ${showPassword ? "fa-eye-slash" : "fa-eye"
                      }`}
                  ></i>
                </button>
              </div>
            </div>
            <div
              className={`transition-opacity duration-1000 ease-in-out ${isPasswordFocused
                ? "opacity-100"
                : "opacity-0 pointer-events-none"
                } max-w-[505px] w-[35vw] min-w-[280px]  items-center justify-center`}
            >
              <div className="flex flex-col mt-3 items-center justify-center">
                <div className="grid grid-cols-2 lap:grid-cols-4 gap-0 relative ">
                  <Validate regex={atLeastOneUppercase} text="1 uppercase" />
                  <Validate regex={atLeastOneLowercase} text="1 lowercase" />
                  <Validate regex={atLeastOneNumber} text="1 number" />
                  <Validate regex={min8chars} text="min 8 characters" />
                </div>
              </div>
            </div>
          </div>
          <p className="text-white text-center text-[12px]">
            By clicking sign up I agree to Duel Cube’s <br />
            <a
              href={process.env.REACT_APP_POLICY_URL}
              target="_blank"
              rel="noopener noreferrer"
              className="text-primaryActive hover:underline font-semibold"
            >
              Privacy Policy
            </a>
            {"  "}&amp;
            <a
              href={process.env.REACT_APP_TERMS_URL}
              target="_blank"
              rel="noopener noreferrer"
              className="text-primaryActive hover:underline font-semibold"
            >
              {"  "} Terms of Use
            </a>
          </p>
          <div className="flex flex-col items-center max-w-[505px] min-w-[280px] w-[35vw]  gap-5">
            <button
              ref={submitRef}
              className="items-center text-black justify-center text-center flex flex-row gap-5 font-bold bg-gradient-to-b from-primaryYellow to-secondaryYellow hover:from-secondaryYellow hover:to-primaryYellow ... w-full h-[51.6px] mx-6 rounded-xl"
              onClick={render}
            >
              Sign Up
            </button>
          </div>
          <p className="text-center text-white">
            Already have an account?{" "}
            <a
              href="/login"
              className="text-primaryYellow hover:text-yellow-600 font-semibold"
            >
              Sign In
            </a>
          </p>
        </div>
      </div>
      <LoadingAnim loading={user.loader} />
    </div>
  );
}
